import { Button } from "antd";
import React, {
  useState,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import CreateTaskAllowance from "./CreateTaskAllowance";
import { useForm } from "react-hook-form";
import { Archive, Edit, Trash } from "../../../../../assets/svg";
import { FaAngleDown, FaAngleUp, FaPlus, FaSearch } from "react-icons/fa";
import { PayRollService, SettingService } from "../../../../../config/axiosUrl";
import Pagination from "../../../../../components/PaginationAPI/Pagination";
import PopupSelector from "./PopupSelector";
import { toast } from "react-toastify";
import Confirmation from "../../../../../components/Confirmation";
import { ShimmerTable } from "react-shimmer-effects";

const TaskAllowence = () => {
  const taskInit = useMemo(
    () => ({
      isSave: 1,
      id: "",
      summary: "",
      name: "",
      display_in_summary_as: "",
      hourly_rate: "",
      export_as: "",
    }),
    []
  );
  const [visibleModal, setVisibleModal] = useState(false);
  const [expanded, setExpanded] = useState(-1);

  const [taskAllowance, setTaskAllowance] = useState({
    data: [],
    loading: true,
    search: "",
  });

  const [popupData, setPopupData] = useState({
    tasks: [],
    payroll: [],
    taskLoading: true,
    payrollLoading: true,
    allTaskLoaded: false,
    allPayrollLoaded: false,
  });

  const [assignState, setAssignState] = useState({
    selected: [],
    visible: false,
    type: "",
    currentEdit: {},
    taskSubmitting: false,
    payrollSubmitting: false,
  });

  const [deleteState, setDeleteState] = useState({
    parentIndex: -1,
    childIndex: -1,
    parentID: "",
    childID: "",
    item: {},
    type: "",
    isDeleting: false,
    visible: false,
  });

  const [onArchive, setArchive] = useState({
    visible: false,
    data: {},
    isSubmitting: false,
    reget: false,
  });

  const [task, setTask] = useState({ ...taskInit });
  const [hitAPI, setHitAPI] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const pageFunctions = {
    toggleExpand: useCallback(function (index) {
      setExpanded((prev) => (prev === index ? -1 : index));
    }, []),
    toggleModal: useCallback(() => {
      reset();
      setVisibleModal((prev) => !prev);
      setTask({ ...taskInit });
    }, []),
    toggleSelector: useCallback((type, currentEdit) => {
      setAssignState((prev) => ({
        ...prev,
        visible: !prev.visible,
        type,
        currentEdit,
        taskSubmitting: false,
        payrollSubmitting: false,
        selected: currentEdit?.[
          type === "task"
            ? "payroll_task_allowance_hourly_task_data"
            : type === "payroll"
            ? "payroll_task_allowance_payroll_category_data"
            : []
        ].map((item) => {
          if (type === "task") {
            return item?.hourly_rate_task?.id;
          }

          if (type === "payroll") {
            return item?.payroll_category?.id;
          }

          return undefined;
        }),
      }));
    }, []),
    onSelect: useCallback((id) => {
      setAssignState((prev) => {
        let selected = prev.selected;

        if (selected.includes(id)) {
          selected.splice(selected.indexOf(id), 1);
        } else {
          selected.push(id);
        }

        return {
          ...prev,
          selected,
        };
      });
    }, []),
    onDelete: useCallback(
      (parentID, parentIndex, item, type, childID, childIndex) => {
        setDeleteState((prev) => ({
          ...prev,
          parentID,
          parentIndex,
          item,
          childID,
          childIndex,
          type,
          visible: !!parentID,
          isDeleting: false,
        }));
      },
      []
    ),
    onDeleteConfirm: async () => {
      await new Promise((res) => {
        setDeleteState((prev) => ({
          ...prev,
          isDeleting: true,
        }));
        res(true);
      });

      let { parentIndex, childIndex, parentID, childID, type } = deleteState;
      if (!type) {
        apiFunctions.deleteTask(parentID, parentIndex);
      }

      if (type === "task") {
        apiFunctions.deleteTaskAllowance(
          childID,
          parentID,
          parentIndex,
          childIndex
        );
      }

      if (type === "payroll") {
        apiFunctions.deletePayroll(childID, parentID, parentIndex, childIndex);
      }
    },
    onEdit: useCallback((data) => {
      let makeData = {};
      Object.keys(taskInit).forEach((item) => {
        makeData[item] = data[item];
      });
      setTask(makeData);
      setVisibleModal(true);
    }, []),
  };

  const { handleSubmit, control, reset } = useForm({
    values: task,
  });

  const apiFunctions = {
    get: useCallback((res) => {
      setTaskAllowance((prev) => ({
        ...prev,
        data: res?.data?.data?.data || [],
        loading: false,
      }));
    }, []),
    getTask: useCallback(function (search = "", cursor = "") {
      SettingService.get(
        `/api/v1/setting/task/get-hourly-rate-tasks-data?search=${search}&cursor=${cursor}`
      ).then((res) => {
        setPopupData((prev) => {
          const filtered = search ? res?.data?.data?.data : [];
          if (!search) {
            const tasks = prev.tasks.concat(res?.data?.data?.data);
            const ids = [];

            tasks.forEach((item) => {
              if (!ids.includes(item.id)) {
                ids.push(item.id);
                filtered.push(item);
              }
            });
          }

          return {
            ...prev,
            tasks: filtered,
            taskLoading: false,
            allTaskLoaded: res?.data?.extra?.totalItems <= filtered.length,
          };
        });
      });
    }, []),
    getPayroll: useCallback(function (search = "", cursor = "") {
      PayRollService.get(
        `/api/v1/payroll/setting/get-payroll-category-data?search=${search}&cursor=${cursor}`
      ).then((res) => {
        setPopupData((prev) => {
          const filtered = search ? res?.data?.data?.data : [];
          if (!search) {
            const payroll = prev.payroll.concat(res?.data?.data?.data);
            const ids = [];

            payroll.forEach((item) => {
              if (!ids.includes(item.id)) {
                ids.push(item.id);
                filtered.push(item);
              }
            });
          }
          return {
            ...prev,
            payroll: filtered,
            payrollLoading: false,
            allPayrollLoaded: res?.data?.extra?.totalItems <= filtered.length,
          };
        });
      });
    }, []),
    assignTaskAllowance: useCallback(async () => {
      await new Promise((resolve) => {
        setAssignState((prev) => ({
          ...prev,
          taskSubmitting: true,
        }));
        resolve(true);
      });

      await PayRollService.post(
        "api/v1/setting/payroll/assign-task-to-task-allowance",
        {
          taskIds: assignState.selected || [],
          payroll_task_allowance_id: assignState.currentEdit.id,
        }
      )
        .then((res) => {
          if (res?.data?.status) {
            const { payroll_task_allowance_hourly_task_data } =
              res?.data?.data?.data;

            setTaskAllowance((prev) => {
              let index = assignState.currentEdit.index;
              prev.data[index]["payroll_task_allowance_hourly_task_data"] =
                payroll_task_allowance_hourly_task_data;

              return prev;
            });
            pageFunctions.toggleSelector();
            toast.success(res?.data?.message);
          } else {
            toast.error(res?.data?.message);
          }
          setAssignState((prev) => ({
            ...prev,
            taskSubmitting: false,
          }));
        })
        .catch((err) => {
          setAssignState((prev) => ({
            ...prev,
            taskSubmitting: false,
          }));
        });
    }, [assignState.selected, assignState.currentEdit]),
    assignPayrollCategory: useCallback(async () => {
      await new Promise((resolve) => {
        setAssignState((prev) => ({
          ...prev,
          payrollSubmitting: true,
        }));
        resolve(true);
      });

      PayRollService.post(
        "api/v1/setting/payroll/assign-payroll-category-to-task-allowance",
        {
          payrollCategoryIds: assignState.selected || [],
          payroll_task_allowance_id: assignState.currentEdit.id,
        }
      )
        .then((res) => {
          if (res?.data?.status) {
            const { payroll_task_allowance_payroll_category_data } =
              res?.data?.data?.data;

            setTaskAllowance((prev) => {
              let index = assignState.currentEdit.index;
              prev.data[index]["payroll_task_allowance_payroll_category_data"] =
                payroll_task_allowance_payroll_category_data;

              return prev;
            });
            toast.success(res?.data?.message);
            pageFunctions.toggleSelector();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          setAssignState((prev) => ({
            ...prev,
            payrollSubmitting: false,
          }));
        });
    }, [assignState.selected, assignState.currentEdit]),
    deletePayroll: useCallback((id, taskID, parentIndex, childIndex) => {
      PayRollService.post(
        "api/v1/setting/payroll/delete-assign-payroll-category-task-allowance",
        {
          id: id,
          payroll_task_allowance_id: taskID,
        }
      )
        .then((res) => {
          if (res?.data?.status) {
            setTaskAllowance((prev) => {
              if (parentIndex > -1 && childIndex > -1) {
                prev.data[
                  parentIndex
                ].payroll_task_allowance_payroll_category_data.splice(
                  childIndex,
                  1
                );
              }
              return prev;
            });
            pageFunctions.onDelete();
            toast.success(res?.data?.message);
          } else {
            toast.error(res?.data?.message);
            setDeleteState((prev) => ({
              ...prev,
              isDeleting: false,
            }));
          }
        })
        .catch((err) => {
          toast.error("Failed to remove item");
          setDeleteState((prev) => ({
            ...prev,
            isDeleting: false,
          }));
        });
    }, []),
    deleteTask: useCallback((id, index) => {
      PayRollService.post("api/v1/setting/payroll/delete-task-allowance", {
        id,
      })
        .then((res) => {
          if (res?.data?.status) {
            setTaskAllowance((prev) => {
              prev.data.splice(index, 1);
              return prev;
            });
            toast.success(res?.data?.message);
            pageFunctions.onDelete();
          } else {
            toast.error(res?.data?.message);
            setDeleteState((prev) => ({
              ...prev,
              isDeleting: false,
            }));
          }
        })
        .catch((err) => {
          toast.error("Failed to Delete Task Allowance");
          setDeleteState((prev) => ({
            ...prev,
            isDeleting: false,
          }));
        });
    }, []),

    deleteTaskAllowance: useCallback((id, taskID, parentIndex, childIndex) => {
      PayRollService.post(
        "api/v1/setting/payroll/delete-assign-task-allowance",
        {
          id,
          payroll_task_allowance_id: taskID,
        }
      )
        .then((res) => {
          if (res?.data?.status) {
            setTaskAllowance((prev) => {
              if (parentIndex > -1 && childIndex > -1) {
                prev.data[
                  parentIndex
                ].payroll_task_allowance_hourly_task_data.splice(childIndex, 1);
              }
              return prev;
            });

            pageFunctions.onDelete();
            toast.success(res?.data?.message);
          } else {
            toast.error(res?.data?.message);
            setDeleteState((prev) => ({
              ...prev,
              isDeleting: false,
            }));
          }
        })
        .catch((err) => {
          toast.error("Failed to remove item");

          setDeleteState((prev) => ({
            ...prev,
            isDeleting: false,
          }));
        });
    }, []),
    onSubmit: useCallback(async (data) => {
      await new Promise((res) => {
        setIsSubmitting(true);
        res(true);
      });

      PayRollService.post("api/v1/setting/payroll/edit-create-task-allowance", {
        ...data,
        isSave: data?.id ? 0 : 1,
      })
        .then((res) => {
          if (res?.data?.status) {
            toast.success(res?.data?.message);
            setTask({ ...taskInit });
            setVisibleModal(false);
            reset();
            setHitAPI((prev) => !prev);
          } else {
            toast.error(res?.data?.message);
          }

          setIsSubmitting(false);
        })
        .catch((err) => {
          toast.error("Failed to save payroll");
          setIsSubmitting(false);
        });
    }, []),
  };

  useEffect(() => {
    apiFunctions.getTask();
    apiFunctions.getPayroll();
  }, []);

  const arciveAgreements = useCallback(async () => {
    await new Promise((resolve) => {
      setArchive((prev) => ({
        ...prev,
        isSubmitting: true,
      }));
      resolve(true);
    });

    PayRollService.post("api/v1/payroll/setting/archive-task-allowance-by-id", {
      id: onArchive.data?.id || "",
    })
      .then((res) => {
        if (res?.data?.status) {
          setArchive((prev) => ({
            ...prev,
            isSubmitting: false,
            visible: false,
            data: {},
            reget: !prev.reget,
          }));
          toast.success(res?.data?.message);
        } else {
          setArchive((prev) => ({
            ...prev,
            isSubmitting: false,
          }));
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        setArchive((prev) => ({
          ...prev,
          isSubmitting: false,
        }));
        toast.error("Failed to Archive Agreements");
      });
  }, [onArchive]);

  const onArchiveClick = useCallback((data) => {
    setArchive((prev) => ({
      ...prev,
      visible: !!data,
      data: data || {},
    }));
  }, []);

  const selectorSearch = (search) => {
    if (assignState.type === "task") {
      apiFunctions.getTask(search);
    } else if (assignState.type === "payroll") {
      apiFunctions.getPayroll(search);
    }
  };

  return (
    <div className=" bg-white">
      <CreateTaskAllowance
        toggleModal={pageFunctions.toggleModal}
        visible={visibleModal}
        control={control}
        onSubmit={handleSubmit(apiFunctions.onSubmit)}
        isSubmitting={isSubmitting}
      />
      <Confirmation
        open={onArchive.visible}
        title={`Are you sure want to archive this Task Allowance`}
        details={onArchive.data?.name}
        onConfirm={arciveAgreements}
        isSubmitting={onArchive.isSubmitting}
        onCancel={() => onArchiveClick()}
        buttonText="Archive"
      />

      <PopupSelector
        visible={assignState.visible}
        title={
          assignState.type === "task"
            ? "Available Payroll Tasks"
            : assignState.type === "payroll"
            ? "Available Payroll Categories"
            : ""
        }
        searchPlaceholder={
          assignState.type === "task"
            ? "Search for Payroll Tasks"
            : assignState.type === "payroll"
            ? "Search for Payroll Categories"
            : ""
        }
        onHide={() => pageFunctions.toggleSelector()}
        loading={
          assignState.type === "task"
            ? popupData.taskLoading
            : assignState.type === "payroll"
            ? popupData.payrollLoading
            : true
        }
        isSubmitting={
          assignState.type === "task"
            ? assignState.taskSubmitting
            : assignState.type === "payroll"
            ? assignState.payrollSubmitting
            : false
        }
        data={
          assignState.type === "task"
            ? popupData.tasks
            : assignState.type === "payroll"
            ? popupData.payroll
            : []
        }
        onSearch={selectorSearch}
        allLoaded={
          assignState.type === "task"
            ? popupData.allTaskLoaded
            : assignState.type === "payroll"
            ? popupData.allPayrollLoaded
            : true
        }
        buttonText="Assign"
        onScrollEnd={(search) =>
          assignState.type === "task"
            ? apiFunctions.getTask(
                search,
                popupData.tasks[popupData.tasks.length - 1].id
              )
            : assignState.type === "payroll"
            ? apiFunctions.getPayroll(
                search,
                popupData.payroll[popupData.payroll.length - 1].id
              )
            : () => {}
        }
        onSubmit={
          assignState.type === "task"
            ? apiFunctions.assignTaskAllowance
            : assignState.type === "payroll"
            ? apiFunctions.assignPayrollCategory
            : () => {}
        }
        onCheck={pageFunctions.onSelect}
        selected={assignState.selected}
      />
      <Confirmation
        title={
          deleteState.childID
            ? "Are You Sure You Wish To Remove This Item"
            : "Are You Sure You Wish To Delete This Task Allowance"
        }
        open={deleteState.visible}
        details={deleteState?.item?.name}
        detailsTitle="Name"
        onCancel={() => pageFunctions.onDelete()}
        onConfirm={pageFunctions.onDeleteConfirm}
        isSubmitting={deleteState?.isDeleting}
      />
      <div className="flex justify-between items-center p-5">
        <div className="border border-[#111111] flex items-center p-1 rounded-md w-full lg:w-1/3 my-2 bg-white">
          <FaSearch className="ml-2" />
          <input
            placeholder="Search by Task Allowance Name"
            className="h-[31px] bg-transparent text-sm w-full px-2"
            onChange={(e) =>
              setTaskAllowance((prev) => ({
                ...prev,
                search: e.target.value,
              }))
            }
          />
        </div>
        <Button
          onClick={pageFunctions.toggleModal}
          className="flex justify-center items-center py-3 border border-[#111111] h-[42px]"
        >
          Create New
          <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
            <FaPlus />
          </div>
        </Button>
      </div>
      {taskAllowance.loading ? (
        <ShimmerTable row={6} col={1} />
      ) : (
        <table className="w-full">
          <thead className="bg-[#E8F3FF] text-center">
            <tr className="text-left">
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-start">Name</div>
              </th>
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-center">
                  Hourly Rate
                </div>
              </th>
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-center">Actions</div>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {taskAllowance.data?.map((item, index) => (
              <Fragment key={index}>
                <tr className="border-b-2 border-[#F0F0F0]">
                  <td className="px-4 py-3">
                    <div className="flex items-center justify-start">
                      {item.name}
                    </div>
                  </td>
                  <td className="px-4 py-3">
                    <div className="flex items-center justify-center">
                      {item.hourly_rate}
                    </div>
                  </td>
                  <td className="px-4 py-3">
                    <div className="flex gap-4 items-center justify-center">
                      <button className="text-black bg-transparent border-none">
                        <Trash
                          onClick={() =>
                            pageFunctions.onDelete(item.id, index, item)
                          }
                        />
                      </button>
                      <button className="text-black bg-transparent border-none">
                        <Archive onClick={() => onArchiveClick(item)} />
                      </button>
                      <button className="text-black bg-transparent border-none">
                        <Edit onClick={() => pageFunctions.onEdit(item)} />
                      </button>

                      <Button
                        className="px-2 bg-[#FE650C]"
                        onClick={() => pageFunctions.toggleExpand(index)}
                      >
                        {expanded === index ? (
                          <FaAngleUp className="scale-125 fill-white" />
                        ) : (
                          <FaAngleDown className="scale-125 fill-white" />
                        )}
                      </Button>
                    </div>
                  </td>
                </tr>
                {expanded === index && (
                  <Fragment>
                    <tr>
                      <td colSpan={3}>
                        <div className="w-full flex justify-center py-3">
                          <div className="w-[80%]">
                            <span className="flex justify-between">
                              <h1 className="font-semibold">
                                {`${item.name} is applied to Tasks`}
                              </h1>
                              <Button
                                className="flex justify-center items-center py-1 px-2 border border-[#111111] h-[34px]"
                                onClick={() =>
                                  pageFunctions.toggleSelector("task", {
                                    ...item,
                                    index,
                                  })
                                }
                              >
                                Assign
                                <div className="bg-orange-500 p-1 rounded-md text-white ml-3">
                                  <FaPlus />
                                </div>
                              </Button>
                            </span>
                            <div className="pt-4">
                              {item?.payroll_task_allowance_hourly_task_data?.map(
                                (childTask, taskIndex) => (
                                  <div className="flex justify-between px-4 py-2 border-b-2 border-b-[#F0F0F0]">
                                    <p>{childTask?.hourly_rate_task?.name}</p>
                                    <div className="cursor-pointer">
                                      <Trash
                                        onClick={() =>
                                          pageFunctions.onDelete(
                                            item.id,
                                            index,
                                            childTask?.hourly_rate_task,
                                            "task",
                                            childTask.id,
                                            taskIndex
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <div className="w-full flex justify-center py-3">
                          <div className="w-[80%]">
                            <span className="flex justify-between">
                              <h1 className="font-semibold">
                                {`${item.name} is Applied to Payroll Categories`}
                              </h1>
                              <Button
                                className="flex justify-center items-center py-1 px-2 border border-[#111111] h-[34px]"
                                onClick={() =>
                                  pageFunctions.toggleSelector("payroll", {
                                    ...item,
                                    index,
                                  })
                                }
                              >
                                Assign
                                <div className="bg-orange-500 p-1 rounded-md text-white ml-3">
                                  <FaPlus />
                                </div>
                              </Button>
                            </span>
                            <div className="pt-4">
                              {item?.payroll_task_allowance_payroll_category_data?.map(
                                (childPayroll, payrollIndex) => (
                                  <div className="flex justify-between px-4 py-2 border-b-2 border-b-[#F0F0F0]">
                                    <p>
                                      {childPayroll?.payroll_category?.name}
                                    </p>
                                    <div className="cursor-pointer">
                                      <Trash
                                        onClick={() =>
                                          pageFunctions.onDelete(
                                            item.id,
                                            index,
                                            childPayroll?.payroll_category,
                                            "payroll",
                                            childPayroll.id,
                                            payrollIndex
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </Fragment>
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
      )}
      <Pagination
        api="api/v1/setting/payroll/get-task-allowance"
        apiService={PayRollService}
        getRes={apiFunctions.get}
        extraParams={{ search: taskAllowance.search }}
        dependecy={[hitAPI, taskAllowance.search, onArchive.reget]}
      />
    </div>
  );
};

export default TaskAllowence;
