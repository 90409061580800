import React, { PureComponent, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

class CustomizedXAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <g margin={10} transform={`translate(${x},${y})`}>
        <text
          x={-10}
          y={0}
          dy={5}
          dx={-30}
          fontSize={14}
          textAnchor="middle"
          fill="#A1A1A1"
          fontFamily='"Lexend", sans-serif'
          transform="rotate(270)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

const Stacked = ({ data }) => {
  return (
    <ResponsiveContainer className={"barC"} width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data?.data?.overview}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          height={160}
          dataKey="name"
          textAnchor="end"
          claeToFit="true"
          verticalAnchor="start"
          interval={0}
          angle="-90"
          stroke="#A1A1A1"
        />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="worked" name="Worked" stackId="name" fill="#FFD43B" />
        <Bar
          dataKey="remaining_estimated"
          name="Remaining Estimated"
          stackId="name"
          fill="#748FFC"
        />
        <Bar dataKey="lost" name="Lost" stackId="name" fill="#F03E3E" />
        <Bar
          dataKey="remaining_reserve"
          name="Remaining Reserve"
          stackId="name"
          fill="#40C057"
        />
        <Bar dataKey="deficit" name="Deficit" stackId="name" fill="#FF922B" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Stacked;
