import React from "react";
import { Input, Modal, Select } from "antd";
import { Button } from "antd";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";

const BertModal = ({ visible, onCancel, isEditing, editData, onSave }) => {
  const modalTitle = isEditing ? "Editing Bewt" : "Creating BERT";

  const { control, handleSubmit, reset } = useForm({
    defaultValues: isEditing ? editData : {}, // Use an empty object for new entries
  });

  useEffect(() => {
    // Reset the form with new initial values when visible and editing
    if (visible && isEditing) {
      reset(editData);
    } else {
      reset(); // Reset the form without preloading data when creating new
    }
  }, [visible, isEditing, reset, editData]);

  const onSubmit = (data) => {
    console.log("the data", data);
    reset();
    onSave(data);
    // Pass the form data to the onSave function in Misc
  };

  return (
    <Modal
      title={modalTitle}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="item mt-5">
          <label htmlFor="name">Name</label>
          {/* Use Controller for the Name input */}
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input type="text" className="mt-3" {...field} />
            )}
          />
        </div>
        <div className="item mt-5">
          <label htmlFor="summary_column">Summary Column</label>
          <Controller
            name="summary_column"
            control={control}
            render={({ field }) => (
              <Input type="text" className="mt-3" {...field} />
            )}
          />
        </div>
        <div className="item mt-5">
          <label htmlFor="rate">Rate</label>
          <Controller
            name="rate"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                className="mt-3"
                id="rate"
                min="0"
              />
            )}
          />
        </div>
        <div className="item mt-5">
          <label htmlFor="frequency">Frequency</label>
          {/* Use Controller for the Frequency Select */}
          <Controller
            name="frequency"
            control={control}
            render={({ field }) => (
              <Select className="mt-3 w-full" {...field}>
                <Select.Option value="1">Per Week</Select.Option>
              </Select>
            )}
          />
        </div>
        <div className="item mt-5 text-center">
          <Button className="btn btn-blue" htmlType="submit">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default BertModal;
