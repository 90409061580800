import React from "react";
import { FaCaretDown, FaEdit, FaInfoCircle, FaTrashAlt } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { Button } from "antd";
import { ButtonSpinner } from "../../components/spinners";
import { Fileurl } from "../../config/url";
import DefaultCree8Img from "../../assets/images/Default/defaulCree8.png";
import { useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useWindowDimensions from "../../utils/routes/useWindowDimensions";
const WorkersTable = ({ data, setData, handle, hasStatus }) => {
  const { width } = useWindowDimensions();
  let { type } = useParams();
  const paths = {
    1: "active",
    3: "terminated",
  };

  const showToast = (message, type) => {
    toast[type](message, { position: "bottom-right" });
  };
  useEffect(() => {
    if (data.isRowLoading && data.rowIndex !== null) {
      const workerName = data.data[data.rowIndex]?.name;
      const statusAction =
        data.data[data.rowIndex]?.status === 3 ? "Revoked" : "Terminated";

      showToast(
        `${workerName} has been ${statusAction} successfully.`,
        "success"
      );
    }
  }, [data.isRowLoading, data.rowIndex, data.data]);

  let navigate = useNavigate();

  const img = {
    display: "block",
    width: 60,
    height: 60,
    borderRadius: "10px",
  };

  return (
    <table className="table-auto w-full">
      <thead className="bg-[#E8F3FF]">
        <tr>
          <th className="px-3 py-3">
            <div className="flex items-center justify-center"></div>
          </th>
          <th className="px-1 py-3">
            <div className="flex items-center justify-center">Worker Name</div>
          </th>
          <th className="px-1 py-3">
            <div className="flex items-center justify-center px-4">Email</div>
          </th>
          {!hasStatus && (
            <th className="px-1 py-3">
              <div className="flex items-center justify-center">Employment</div>
            </th>
          )}

          <th className="px-3 py-3">
            <div className="flex items-center justify-center">Card Id</div>
          </th>
          <th className="px-3 py-3">
            <div className="flex items-center justify-center">
              {type == "ALL" ? "Status" : "Change Status"}
            </div>
          </th>
          <th className="px-3 py-3">
            <div className="flex items-center justify-center">Action</div>
          </th>
        </tr>
      </thead>
      <tbody className="bg-white">
        {data?.data?.map((item, index) => (
          <tr key={index} className="border-b border-[#F0F0F0]">
            <td className="p-3">
              <Link
                to={`/admin/personnel/workers/details/${paths[item.status]}/${
                  item?.id
                }`}
              >
                <img
                  src={
                    item?.users_profile_details?.data
                      ? Fileurl + item?.users_profile_details?.data
                      : DefaultCree8Img
                  }
                  alt="Worker"
                  style={img}
                />
              </Link>
            </td>
            <td className="px-1 py-3 break-word-1 text-center">
              <Link
                to={`/admin/personnel/workers/details/${paths[item.status]}/${
                  item?.id
                }`}
              >
                {item?.name}
              </Link>
            </td>
            <td className="px-3 py-3 break-word-1 text-center">
              <Link
                to={`/admin/personnel/workers/details/${paths[item.status]}/${
                  item?.id
                }`}
              >
                {item?.email}
              </Link>
            </td>
            <td className="px-3 py-3 break-word-1 text-center">
              <Link
                to={`/admin/personnel/workers/details/${paths[item.status]}/${
                  item?.id
                }`}
              >
                {item?.worker_details?.[0]?.employment_status?.name}
              </Link>
            </td>
            <td className="px-3 py-3 break-word-1">
              <div className="flex items-center justify-center  ">
                <Link
                  to={`/admin/personnel/workers/details/${paths}/${item?.id}`}
                >
                  {item?.employee_id ? item?.employee_id : "N/A"}
                </Link>
              </div>
            </td>
            <td className="px-3 py-3 tbottom-right">
              {type == "ALL" ? (
                <>{item?.status == 1 ? "Active" : "Terminated"}</>
              ) : (
                <>
                  <div
                    className="flex items-center justify-center cursor-pointer"
                    onClick={() =>
                      handle?.terminatRevoke(
                        item?.status == 3 ? 1 : item?.status == 1 ? 3 : "",
                        item?.id,
                        index
                      )
                    }
                  >
                    {data?.isRowLoading && data?.rowIndex == index ? (
                      <ButtonSpinner />
                    ) : item?.status == 3 ? (
                      "Revoke"
                    ) : item?.status == 1 ? (
                      "Terminate"
                    ) : (
                      ""
                    )}
                    {data?.isRowLoading && data?.rowIndex == index ? (
                      ""
                    ) : (
                      <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
                        <FaPlus />
                      </div>
                    )}
                  </div>
                </>
              )}
            </td>
            <td className="px-3 py-3">
              <div className="flex items-center justify-center">
                <button
                  className="mx-2"
                  onClick={() => handle?.edit(item, index)}
                >
                  <FaEdit title="Edit" />
                </button>
                <button className="mx-2">
                  <Link
                    to={`/admin/personnel/workers/details/${
                      paths[item.status]
                    }/${item?.id}`}
                  >
                    <FaInfoCircle title="History" />
                  </Link>
                </button>
                <button className="mx-2">
                  <FaTrashAlt
                    title="Delete"
                    onClick={() => handle?.delete(item, index)}
                  />
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default WorkersTable;
