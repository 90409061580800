import { Button, Checkbox, DatePicker, Popover, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { FaLock, FaPlus, FaTimes } from "react-icons/fa";
import { AppContext } from "../../layouts/Main";
import { ApiAuthorizaion, Authuser } from "../../auth/appAuth";
import {
  ChangeWorkerStatusOverride,
  ChangeWorkerStatusRoster,
  ChangeWorkerStatusUnRoster,
  GetWorkersType,
  MarkPresent,
  SplitDate,
  approveWorkerShiftDetails,
  getNextShiftDetails,
  getPrevShiftDetails,
  getShiftDetails,
} from "../../helper/worksite/worksite";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PayRollService } from "../../config/axiosUrl";
import AbsentModal from "./absentModal";
import CreatingWorkShiftModal from "./CreatingWorkShiftModal";
import GreenPlusIcon from "../../assets/svg/greenplusicon";
import { ButtonSpinner } from "../../components/spinners";
import dayjs from "dayjs";
import { GetPayrollCatagoryId } from "../../helper/setup/payroll/payrollcatagory";
import {
  assignWorkerPayrollData,
  assignWorkerPayrollmultiple,
  assignWorkerPayrollmultipleWork,
} from "../../helper/worksite/worksiteDetails.js/areas";
import ApproveModal from "./approveModal";
import useWindowDimensions from "../../utils/routes/useWindowDimensions";

const ShiftDetailsTable = ({ worksite, setWorksite }) => {
  const { width } = useWindowDimensions();
  const initialvalue = {
    id: "",
    name: "",
    email: "",
    employee_id: "",
    mobile: "",
    role: "",
    verified: "",
    last_login: "",
    status: "",
  };
  const [user, setUser] = useState(initialvalue);
  const { id } = useParams();
  const userData = localStorage.getItem("dataStore");
  const [dataStore, setDataStore] = useContext(AppContext);
  const [shiftDetails, setShiftDetails] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [absenteModal, setAbsentModal] = useState(false);
  const [absenseType, setAbsenseType] = useState();
  const [assignTaskModal, setAssignTask] = useState(false);
  const [keyArray, setKeyArr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const idArr = [];
  const [idsArrayList, setIdsArrayList] = useState(idArr);
  const [selectedWorker, setSelectedWorker] = useState();
  const [inductionPending, setInductionPending] = useState(false);
  const [isLoadingOverride, setIsLoadingOverride] = useState(false);
  const [payrollpending, setPayrollPending] = useState(false);
  const [workDayPending, setWorkDayPending] = useState(false);
  const [empStatePending, setEmpDayPending] = useState(false);
  const [selectedData, setSelectedData] = useState({ workerData: [] });
  const [empStatus, setEmpStatus] = useState(false);
  const [payrollData, setPayrollData] = useState();
  const [selectedWorkerItem, setSelectedWorkerItem] = useState();
  const [payrollPendingData, setPayrollPendingData] = useState(false);
  const [payrollDataArr, setPayrollDataArr] = useState([]);
  const [payrollModal, setPayrollModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState([]);
  const [assignedWorker, setAssignedWorker] = useState([]);
  const [selectedWorkerId, setSelectedWorkerId] = useState();
  const [approveModal, setApproveModal] = useState(false);

  useEffect(() => {
    let data = Authuser();
    setDataStore(JSON.parse(userData));
    setUser({
      ...data,
    });

    const targetSubstring = "/site-manager/worksite/details/shift-details";
    const isMatch = window.location.pathname.includes(targetSubstring);
    if (isMatch) {
      fetchShiftDetails();
    }
  }, [window.location.pathname]);
  const overRideWorker = async () => {
    setIsLoadingOverride(true);
    let response = await ChangeWorkerStatusOverride(
      selectedItem,
      worksite,
      id,
      true
    );
    if (response?.status) {
      setIsLoadingOverride(false);
      setInductionPending(false);
      fetchShiftDetails();
    }
  };
  const colonTime = (time) => {
    let newTime = time.toString().replace(".", ":");
    return newTime;
  };
  const fetchShiftDetails = async () => {
    let res = await getShiftDetails(worksite);
    if (res?.status) {
      setShiftDetails({
        ...shiftDetails,
        data: res?.data?.data,
        extra: res?.extra,
      });
      setKeyArr(Object.keys(res?.data?.data[0]?.timesheetData));
      setStartDate(
        SplitDate(res?.data?.data[0]?.timesheetData?.Saturday?.date)
      );
      setEndDate(SplitDate(res?.data?.data[0]?.timesheetData?.Friday?.date));
      res?.data?.data?.map((item, index) => {
        if (
          item?.timesheetData?.[
            Object.keys(res?.data?.data[0]?.timesheetData)[0]
          ]?.timesheetData?.length > 0
        ) {
          idArr.push(
            item?.timesheetData?.[
              Object.keys(res?.data?.data[0]?.timesheetData)[0]
            ]?.timesheetData[0]?.worksite_timesheet[0]?._id
          );
        }
        if (
          item?.timesheetData?.[
            Object.keys(res?.data?.data[0]?.timesheetData)[1]
          ]?.timesheetData?.length > 0
        ) {
          idArr.push(
            item?.timesheetData?.[
              Object.keys(res?.data?.data[0]?.timesheetData)[1]
            ]?.timesheetData[0]?.worksite_timesheet[0]?._id
          );
        }
        if (
          item?.timesheetData?.[
            Object.keys(res?.data?.data[0]?.timesheetData)[2]
          ]?.timesheetData?.length > 0
        ) {
          idArr.push(
            item?.timesheetData?.[
              Object.keys(res?.data?.data[0]?.timesheetData)[2]
            ]?.timesheetData[0]?.worksite_timesheet[0]?._id
          );
        }
        if (
          item?.timesheetData?.[
            Object.keys(res?.data?.data[0]?.timesheetData)[3]
          ]?.timesheetData?.length > 0
        ) {
          idArr.push(
            item?.timesheetData?.[
              Object.keys(res?.data?.data[0]?.timesheetData)[3]
            ]?.timesheetData[0]?.worksite_timesheet[0]?._id
          );
        }
        if (
          item?.timesheetData?.[
            Object.keys(res?.data?.data[0]?.timesheetData)[4]
          ]?.timesheetData?.length > 0
        ) {
          idArr.push(
            item?.timesheetData?.[
              Object.keys(res?.data?.data[0]?.timesheetData)[4]
            ]?.timesheetData[0]?.worksite_timesheet[0]?._id
          );
        }
        if (
          item?.timesheetData?.[
            Object.keys(res?.data?.data[0]?.timesheetData)[5]
          ]?.timesheetData?.length > 0
        ) {
          idArr.push(
            item?.timesheetData?.[
              Object.keys(res?.data?.data[0]?.timesheetData)[5]
            ]?.timesheetData[0]?.worksite_timesheet[0]?._id
          );
        }
        if (
          item?.timesheetData?.[
            Object.keys(res?.data?.data[0]?.timesheetData)[6]
          ]?.timesheetData?.length > 0
        ) {
          idArr.push(
            item?.timesheetData?.[
              Object.keys(res?.data?.data[0]?.timesheetData)[6]
            ]?.timesheetData[0]?.worksite_timesheet[0]?._id
          );
        }
        setIdsArrayList(idArr);
      });
    }
  };
  const openPayrollModal = (workerId) => {
    setPayrollModal(true);
    fetchPayrollData(workerId);
  };
  const closePayrollModal = () => {
    setPayrollModal(false);
  };
  const checkPayroll = (e, item) => {
    if (e?.target?.checked) {
      // setSelectedTask([item?.id]);
      setSelectedTask([...selectedTask, item?.id]);
    } else {
      const updatedTaskArr = selectedTask.filter(
        (taskId) => taskId !== item?.id
      );
      setSelectedTask(updatedTaskArr);
    }
  };
  const assignPayroll = async () => {
    setAssignedWorker([...assignedWorker, selectedWorkerId]);
    // let res = await assignWorkerPayroll(selectedTask, data?.selectedOption)
    let res = await assignWorkerPayrollmultipleWork(selectedTask, worksite);
    if (res?.status) {
      toast.success(res?.message);
      closePayrollModal();
      // setEmpDayPending(false)
      // setPayrollPending(false)
      // closeModal()
      setEmpStatus(false);
      rosteredTask();
    }
  };
  const fetchTask = async (e) => {
    if (
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      e.currentTarget.clientHeight
    ) {
      const params = {
        cursor: shiftDetails?.extra?.cursor,
      };
      // if (worksite?.extra?.totalItems != worksite?.data?.length) {
      let data = await getShiftDetails(params);
      if (data?.status) {
        let alldata = [...shiftDetails?.data];
        if (data?.data?.data?.length > 0) {
          data?.data?.data?.map((site, index) => {
            alldata?.push({ ...site });
          });

          setShiftDetails({
            ...shiftDetails,
            data: alldata,
            isFetchLoader: false,
            extra: data?.extra,
          });
        } else {
          setShiftDetails({
            ...shiftDetails,
            isFetchLoader: false,
          });
        }
      } else {
        setShiftDetails({
          ...shiftDetails,
          isFetchLoader: false,
        });
      }
      // }
      // else {
      //   setShiftDetails({
      //     ...shiftDetails,
      //     isFetchLoader: false,
      //   });
      // }
    }
  };
  const handleChangePayrollArr = (value, index, work_id) => {
    let allPrev = [...selectedData?.workerData];
    let updatedElement = { ...allPrev[index] };
    updatedElement.payroll_ids = [value];
    updatedElement.worker_id = work_id;
    allPrev[index] = updatedElement;
    setSelectedData({ ...selectedData, workerData: allPrev });
  };
  const openPayrollCategory = async (empId, workerId) => {
    const res = await GetPayrollCatagoryId(empId, workerId);
    if (res?.status) {
      setPayrollDataArr(res?.data?.data);
    }
  };
  const assignPayrollWorker = async () => {
    let res = await assignWorkerPayrollmultiple(selectedData, worksite);
    if (res?.status) {
      toast?.success(res?.message);
      setEmpDayPending(false);
      rosteredTask();
    }
  };
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const rosteredTask = async () => {
    let data = await ChangeWorkerStatusRoster(selectedItem, worksite, id, true);
    if (data?.status) {
      toast?.success(data?.message);
      fetchShiftDetails();
    } else {
      if (data?.status_code == "IND_PEN") {
        setSelectedWorkerItem(data?.data?.userData);
        setInductionPending(true);
        setIsLoading(false);
      }
      if (data?.status_code == "PAYR_PEN") {
        setSelectedWorkerItem(data?.data?.userData);
        setPayrollPending(true);
        setIsLoading(false);
      }
      if (data?.status_code == "WORK_PAY_PENDING") {
        setSelectedWorkerItem(data?.data?.userData);
        fetchPayrollData();
        setWorkDayPending(true);
        setIsLoading(false);
      }
      if (data?.status_code == "EMP_STAT_PENDING") {
        setSelectedWorkerItem(data?.data?.userData);
        fetchPayrollData();
        setEmpDayPending(true);
        const dataArr = { worker_id: "", payroll_ids: "" };
        let userData = data?.data?.userData?.length;
        for (let n = userData; n > 0; --n) {
          selectedData?.workerData.push(dataArr);
        }
        setSelectedData({ ...selectedData });
        setIsLoading(false);
      }
      if (data?.status_code == "WORK_EMP_STAT_PENDING") {
        setSelectedWorkerItem(data?.data?.userData);
        fetchPayrollData();
        setEmpStatus(true);
      }
      if (data?.status_code == "WORKER_PAYRO_PEND") {
        setSelectedWorkerItem(data?.data?.userData);
        fetchPayrollData();
        setPayrollPendingData(true);
        const dataarr = { worker_id: "", payroll_ids: "" };
        let userData = data?.data?.userData?.length;
        for (let n = userData; n > 0; --n) {
          selectedData?.workerData.push(dataarr);
        }
        setSelectedData({ ...selectedData });
        setIsLoading(false);
      }
      // else{
      //   toast?.error(data?.message)
      // }
    }
  };
  const assignPayrollWorkerPayroll = async () => {
    let res = await assignWorkerPayrollData(selectedData, dataStore?.id);
    if (res?.status) {
      toast?.success(res?.message);
      setPayrollPendingData(false);
      rosteredTask();
    }
  };
  const fetchPayrollData = async (workerId) => {
    let res = await GetPayrollCatagoryId(workerId ? workerId : "");
    if (res?.status) {
      setPayrollData({ ...payrollData, data: res?.data?.data });
    }
  };
  const unrosteredTask = async () => {
    let data = await ChangeWorkerStatusUnRoster(selectedItem, id);
    if (data?.status) {
      toast?.success(data?.message ? data?.message : "Unrostered Successfully");
      fetchShiftDetails();
    }
  };
  const GetAbsenseType = async () => {
    try {
      const authorization = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: ApiAuthorizaion(),
        },
      };

      let data = await PayRollService?.get(
        `/api/v1/setting/payroll/get-absences-data?type=common`,
        {
          headers: authorization?.headers,
          // params: param
        }
      );
      return data?.data;
    } catch (error) {
      return error;
    }
  };
  const absentTask = async () => {
    setAbsentModal(true);
    let data = await GetAbsenseType();
    if (data?.status) {
      setAbsenseType(data?.data?.data);
    }
  };
  const presentTask = async () => {
    let res = await MarkPresent(worksite, selectedItem, true);
    if (res?.status) {
      toast.success("Status change Successfully");
      fetchShiftDetails();
    }
  };
  const onCloseAbsentModal = () => {
    setAbsentModal(false);
  };
  const assignTaskWorker = async () => {
    setAssignTask(true);
  };
  const closeAssignTaskWorker = async () => {
    setAssignTask(false);
  };
  const onCloseApproveModal = () => {
    setApproveModal(false);
  };
  const approveWorker = async () => {
    setIsLoading(true);
    let idsArray = [];
    let workerId = [];
    shiftDetails?.data?.map((item, index) => {
      if (item?.timesheetData?.[keyArray[0]]?.timesheetData?.length > 0) {
        idsArray.push(
          item?.timesheetData?.[keyArray[0]]?.timesheetData[0]
            ?.worksite_timesheet[0]?._id
        );
        workerId.push(
          item?.timesheetData?.[keyArray[0]]?.timesheetData[0]?.worker_id
        );
      }
      if (item?.timesheetData?.[keyArray[1]]?.timesheetData?.length > 0) {
        idsArray.push(
          item?.timesheetData?.[keyArray[1]]?.timesheetData[0]
            ?.worksite_timesheet[0]?._id
        );
        workerId.push(
          item?.timesheetData?.[keyArray[1]]?.timesheetData[0]?.worker_id
        );
      }
      if (item?.timesheetData?.[keyArray[2]]?.timesheetData?.length > 0) {
        idsArray.push(
          item?.timesheetData?.[keyArray[2]]?.timesheetData[0]
            ?.worksite_timesheet[0]?._id
        );
        workerId.push(
          item?.timesheetData?.[keyArray[2]]?.timesheetData[0]?.worker_id
        );
      }
      if (item?.timesheetData?.[keyArray[3]]?.timesheetData?.length > 0) {
        idsArray.push(
          item?.timesheetData?.[keyArray[3]]?.timesheetData[0]
            ?.worksite_timesheet[0]?._id
        );
        workerId.push(
          item?.timesheetData?.[keyArray[3]]?.timesheetData[0]?.worker_id
        );
      }
      if (item?.timesheetData?.[keyArray[4]]?.timesheetData?.length > 0) {
        idsArray.push(
          item?.timesheetData?.[keyArray[4]]?.timesheetData[0]
            ?.worksite_timesheet[0]?._id
        );
        workerId.push(
          item?.timesheetData?.[keyArray[4]]?.timesheetData[0]?.worker_id
        );
      }
      if (item?.timesheetData?.[keyArray[5]]?.timesheetData?.length > 0) {
        idsArray.push(
          item?.timesheetData?.[keyArray[5]]?.timesheetData[0]
            ?.worksite_timesheet[0]?._id
        );
        workerId.push(
          item?.timesheetData?.[keyArray[5]]?.timesheetData[0]?.worker_id
        );
      }
      if (item?.timesheetData?.[keyArray[6]]?.timesheetData?.length > 0) {
        idsArray.push(
          item?.timesheetData?.[keyArray[6]]?.timesheetData[0]
            ?.worksite_timesheet[0]?._id
        );
        workerId.push(
          item?.timesheetData?.[keyArray[6]]?.timesheetData[0]?.worker_id
        );
      }
    });
    let param = {
      ids: idsArray,
      worker_id: workerId,
      from: SplitDate(shiftDetails?.data[0]?.timesheetData?.Saturday.date),
      to: SplitDate(shiftDetails?.data[0]?.timesheetData?.Friday.date),
    };
    console.log("the params is ", param);
    let res = await approveWorkerShiftDetails(param);
    if (res?.status) {
      toast?.success(res?.message ? res?.message : "Works are approved");
      setIsLoading(false);
      fetchShiftDetails();
    }
    setApproveModal(false);
  };
  const content = (
    <div>
      {selectedItem?.item?.timesheetData?.[selectedItem?.day]?.timesheetData[0]
        ?.is_absence != 1 && (
        <p
          className="px-4 py-2 cursor-pointer border-b-2"
          onClick={
            selectedItem?.item?.timesheetData?.[selectedItem?.day]
              ?.timesheetData[0]?.timesheet_status == "Rostered"
              ? unrosteredTask
              : rosteredTask
          }
        >
          {selectedItem?.item?.timesheetData?.[selectedItem?.day]
            ?.timesheetData[0]?.timesheet_status == "Rostered"
            ? "Unrostered"
            : "Rostered"}
        </p>
      )}
      <p
        className="px-4 py-2 cursor-pointer border-b-2"
        onClick={
          selectedItem?.item?.timesheetData?.[selectedItem?.day]
            ?.timesheetData[0]?.is_absence == 1
            ? presentTask
            : absentTask
        }
      >
        {selectedItem?.item?.timesheetData?.[selectedItem?.day]
          ?.timesheetData[0]?.is_absence == 1
          ? "Present"
          : "Absent"}
      </p>
      {selectedItem?.item?.timesheetData?.[selectedItem?.day]?.timesheetData[0]
        ?.is_absence != 1 && (
        <p className="px-4 py-2 cursor-pointer" onClick={assignTaskWorker}>
          Assign Work
        </p>
      )}
    </div>
  );
  const prevButton = async () => {
    setStartDate(
      SplitDate(
        shiftDetails?.data?.length > 0
          ? shiftDetails?.data[0]?.timesheetData?.Saturday?.date
          : ""
      )
    );
    setEndDate(
      SplitDate(
        shiftDetails?.data?.length > 0
          ? shiftDetails?.data[0]?.timesheetData?.Friday?.date
          : ""
      )
    );
    const res = await getPrevShiftDetails(
      SplitDate(
        shiftDetails?.data?.length > 0
          ? shiftDetails?.data[0]?.timesheetData?.Saturday?.date
          : ""
      )
    );
    if (res?.status) {
      setShiftDetails({
        ...shiftDetails,
        data: res?.data?.data,
        extra: res?.extra,
      });
      setKeyArr(Object.keys(res?.data?.data[0]?.timesheetData));
      setStartDate(
        SplitDate(res?.data?.data[0]?.timesheetData?.Saturday?.date)
      );
      setEndDate(SplitDate(res?.data?.data[0]?.timesheetData?.Friday?.date));
      res?.data?.data?.map((item, index) => {
        if (item?.timesheetData?.[keyArray[0]]?.timesheetData?.length > 0) {
          idArr.push(
            item?.timesheetData?.[keyArray[0]]?.timesheetData[0]
              ?.worksite_timesheet[0]?._id
          );
        }
        if (item?.timesheetData?.[keyArray[1]]?.timesheetData?.length > 0) {
          idArr.push(
            item?.timesheetData?.[keyArray[1]]?.timesheetData[0]
              ?.worksite_timesheet[0]?._id
          );
        }
        if (item?.timesheetData?.[keyArray[2]]?.timesheetData?.length > 0) {
          idArr.push(
            item?.timesheetData?.[keyArray[2]]?.timesheetData[0]
              ?.worksite_timesheet[0]?._id
          );
        }
        if (item?.timesheetData?.[keyArray[3]]?.timesheetData?.length > 0) {
          idArr.push(
            item?.timesheetData?.[keyArray[3]]?.timesheetData[0]
              ?.worksite_timesheet[0]?._id
          );
        }
        if (item?.timesheetData?.[keyArray[4]]?.timesheetData?.length > 0) {
          idArr.push(
            item?.timesheetData?.[keyArray[4]]?.timesheetData[0]
              ?.worksite_timesheet[0]?._id
          );
        }
        if (item?.timesheetData?.[keyArray[5]]?.timesheetData?.length > 0) {
          idArr.push(
            item?.timesheetData?.[keyArray[5]]?.timesheetData[0]
              ?.worksite_timesheet[0]?._id
          );
        }
        if (item?.timesheetData?.[keyArray[6]]?.timesheetData?.length > 0) {
          idArr.push(
            item?.timesheetData?.[keyArray[6]]?.timesheetData[0]
              ?.worksite_timesheet[0]?._id
          );
        }
        setIdsArrayList(idArr);
      });
    }
  };
  const nextButton = async () => {
    setStartDate(
      SplitDate(
        shiftDetails?.data?.length > 0
          ? shiftDetails?.data[0]?.timesheetData?.Saturday?.date
          : ""
      )
    );
    setEndDate(
      SplitDate(
        shiftDetails?.data?.length > 0
          ? shiftDetails?.data[0]?.timesheetData?.Friday?.date
          : ""
      )
    );
    const res = await getNextShiftDetails(
      SplitDate(
        shiftDetails?.data?.length > 0
          ? shiftDetails?.data[0]?.timesheetData?.Friday?.date
          : ""
      )
    );
    if (res?.status) {
      setShiftDetails({
        ...shiftDetails,
        data: res?.data?.data,
        extra: res?.extra,
      });
      setKeyArr(Object.keys(res?.data?.data[0]?.timesheetData));
      setStartDate(
        SplitDate(res?.data?.data[0]?.timesheetData?.Saturday?.date)
      );
      setEndDate(SplitDate(res?.data?.data[0]?.timesheetData?.Friday?.date));
      res?.data?.data?.map((item, index) => {
        if (item?.timesheetData?.[keyArray[0]]?.timesheetData?.length > 0) {
          idArr.push(
            item?.timesheetData?.[keyArray[0]]?.timesheetData[0]
              ?.worksite_timesheet[0]?._id
          );
        }
        if (item?.timesheetData?.[keyArray[1]]?.timesheetData?.length > 0) {
          idArr.push(
            item?.timesheetData?.[keyArray[1]]?.timesheetData[0]
              ?.worksite_timesheet[0]?._id
          );
        }
        if (item?.timesheetData?.[keyArray[2]]?.timesheetData?.length > 0) {
          idArr.push(
            item?.timesheetData?.[keyArray[2]]?.timesheetData[0]
              ?.worksite_timesheet[0]?._id
          );
        }
        if (item?.timesheetData?.[keyArray[3]]?.timesheetData?.length > 0) {
          idArr.push(
            item?.timesheetData?.[keyArray[3]]?.timesheetData[0]
              ?.worksite_timesheet[0]?._id
          );
        }
        if (item?.timesheetData?.[keyArray[4]]?.timesheetData?.length > 0) {
          idArr.push(
            item?.timesheetData?.[keyArray[4]]?.timesheetData[0]
              ?.worksite_timesheet[0]?._id
          );
        }
        if (item?.timesheetData?.[keyArray[5]]?.timesheetData?.length > 0) {
          idArr.push(
            item?.timesheetData?.[keyArray[5]]?.timesheetData[0]
              ?.worksite_timesheet[0]?._id
          );
        }
        if (item?.timesheetData?.[keyArray[6]]?.timesheetData?.length > 0) {
          idArr.push(
            item?.timesheetData?.[keyArray[6]]?.timesheetData[0]
              ?.worksite_timesheet[0]?._id
          );
        }
        setIdsArrayList(idArr);
      });
    }
  };
  return (
    <div>
      <div className="flex items-center justify-between mb-4 p-4">
        <p className={width > 767 ? "text-[20px]" : "text-[13px] mr-2"}>
          {dataStore?.name ? dataStore?.name : dataStore?.jobName}
        </p>
        <div className="flex items-center justify-between">
          <Button className="border px-2 mr-2" onClick={prevButton}>
            &#60;
          </Button>
          <button className="mr-2 ">
            <DatePicker
              format={"YYYY-MM-DD"}
              disabled
              value={dayjs(startDate, "YYYY-MM-DD")}
            />
          </button>
          <p className="mr-2">To</p>
          <button>
            <DatePicker
              format={"YYYY-MM-DD"}
              disabled
              value={dayjs(endDate, "YYYY-MM-DD")}
            />
          </button>
          <Button className="border px-2 ml-2" onClick={nextButton}>
            &#62;
          </Button>
        </div>
        <div className="flex items-center justify-between">
          {idsArrayList?.length > 0 ? (
            <Button
              className={
                width > 767
                  ? "py-3 h-[42px] ml-2 mr-2 flex justify-center items-center"
                  : " ml-2 mr-2 flex justify-center items-center"
              }
              onClick={() => setApproveModal(!approveModal)}
              disabled={shiftDetails?.extra?.approved == true}
            >
              {"Approve"}
            </Button>
          ) : (
            <></>
          )}
          {/* <Button
            // onClick={handleInspection?.add}
            className="flex justify-center items-center py-3 h-[42px]"
          >
            Add New Worker
            <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
              <FaPlus />
            </div>
          </Button> */}
        </div>
      </div>
      <div
        style={{ height: "400px", overflow: "auto" }}
        // className="h-[50vh] overflow-y-scroll"
        onScroll={fetchTask}
      >
        <table className="w-full">
          <thead className="bg-[#E8F3FF] text-center">
            <tr className="text-left">
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-start">Worker</div>
              </th>
              <th className=" px-4 py-3">
                <div className="flex items-center justify-center">
                  SAT{" "}
                  {shiftDetails?.extra?.approved == true && (
                    <FaLock className="ml-2" />
                  )}
                </div>
              </th>
              <th className=" px-4 py-3">
                <div className="flex items-center justify-center">
                  SUN{" "}
                  {shiftDetails?.extra?.approved == true && (
                    <FaLock className="ml-2" />
                  )}
                </div>
              </th>
              <th className=" px-4 py-3">
                <div className="flex items-center justify-center">
                  MON{" "}
                  {shiftDetails?.extra?.approved == true && (
                    <FaLock className="ml-2" />
                  )}
                </div>
              </th>
              <th className=" px-4 py-3">
                <div className="flex items-center justify-center">
                  TUES{" "}
                  {shiftDetails?.extra?.approved == true && (
                    <FaLock className="ml-2" />
                  )}
                </div>
              </th>
              <th className=" px-4 py-3">
                <div className="flex items-center justify-center">
                  WED{" "}
                  {shiftDetails?.extra?.approved == true && (
                    <FaLock className="ml-2" />
                  )}
                </div>
              </th>
              <th className=" px-4 py-3">
                <div className="flex items-center justify-center">
                  THR{" "}
                  {shiftDetails?.extra?.approved == true && (
                    <FaLock className="ml-2" />
                  )}
                </div>
              </th>
              <th className=" px-4 py-3">
                <div className="flex items-center justify-center">
                  FRI{" "}
                  {shiftDetails?.extra?.approved == true && (
                    <FaLock className="ml-2" />
                  )}
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {shiftDetails?.data?.map((item, index) => (
              <tr key={index} className="border-b border-[#F0F0F0]">
                <td className="px-4 py-3">
                  <div className="flex items-center justify-start">
                    {item.name}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-center text-center">
                    {item?.timesheetData?.Saturday?.timesheetData?.length ==
                    0 ? (
                      shiftDetails?.extra?.approved != true ? (
                        <div
                          className="flex items-center justify-center text-center"
                          onClick={() =>
                            setSelectedItem({
                              ...selectedItem,
                              item,
                              day: "Saturday",
                            })
                          }
                        >
                          <Popover
                            placement="top"
                            content={content}
                            trigger="click"
                          >
                            <FaPlus className="text-sm border-2 p-1 border-black w-[20px] h-[20px] flex justify-center items-center rounded-full cursor-pointer marginAuto" />
                          </Popover>
                        </div>
                      ) : (
                        <GreenPlusIcon />
                      )
                    ) : (
                      <div
                        className="flex items-center justify-center text-center"
                        onClick={() =>
                          setSelectedItem({
                            ...selectedItem,
                            item,
                            day: "Saturday",
                          })
                        }
                      >
                        <Popover
                          placement="top"
                          content={
                            shiftDetails?.extra?.approved == true ? "" : content
                          }
                          trigger="click"
                        >
                          <p
                            className={`${
                              shiftDetails?.extra?.approved == true
                                ? "text-[#15803d]"
                                : "cursor-pointer"
                            }`}
                          >
                            {item?.timesheetData?.Saturday?.timesheetData[0]
                              ?.is_absence == 1 ? (
                              "Absent"
                            ) : item?.timesheetData?.Saturday?.timesheetData[0]
                                ?.timesheet_status == "" ? (
                              <Popover
                                placement="top"
                                content={content}
                                trigger="click"
                              >
                                <FaPlus className="text-sm border-2 p-1 border-black w-[20px] h-[20px] flex justify-center items-center rounded-full cursor-pointer marginAuto" />
                              </Popover>
                            ) : item?.timesheetData?.Saturday?.timesheetData[0]
                                ?.timesheet_status == "Confirmed" ? (
                              <p className="font-medium">
                                {colonTime(
                                  item?.timesheetData?.Saturday
                                    ?.timesheetData[0]?.started
                                )}{" "}
                                -{" "}
                                {colonTime(
                                  item?.timesheetData?.Saturday
                                    ?.timesheetData[0]?.finished
                                )}
                                <span className="text-[#757575] block">
                                  {item?.timesheetData?.Saturday
                                    ?.timesheetData[0]?.spent > 0 &&
                                    item?.timesheetData?.Saturday
                                      ?.timesheetData[0]?.spent}
                                </span>
                              </p>
                            ) : (
                              item?.timesheetData?.Saturday?.timesheetData[0]
                                ?.timesheet_status
                            )}
                          </p>
                        </Popover>
                      </div>
                    )}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-center text-center">
                    {item?.timesheetData?.Sunday?.timesheetData?.length == 0 ? (
                      shiftDetails?.extra?.approved != true ? (
                        <div
                          className="flex items-center justify-center text-center"
                          onClick={() =>
                            setSelectedItem({
                              ...selectedItem,
                              item,
                              day: "Sunday",
                            })
                          }
                        >
                          <Popover
                            placement="top"
                            content={content}
                            trigger="click"
                          >
                            <FaPlus className="text-sm border-2 p-1 border-black w-[20px] h-[20px] flex justify-center items-center rounded-full cursor-pointer marginAuto" />
                          </Popover>
                        </div>
                      ) : (
                        <GreenPlusIcon />
                      )
                    ) : (
                      <div
                        className="flex items-center justify-center text-center"
                        onClick={() =>
                          setSelectedItem({
                            ...selectedItem,
                            item,
                            day: "Sunday",
                          })
                        }
                      >
                        <Popover
                          placement="top"
                          content={
                            shiftDetails?.extra?.approved == true ? "" : content
                          }
                          trigger="click"
                        >
                          <p
                            className={`${
                              shiftDetails?.extra?.approved == true
                                ? "text-[#15803d]"
                                : "cursor-pointer"
                            }`}
                          >
                            {item?.timesheetData?.Sunday?.timesheetData[0]
                              ?.is_absence == 1 ? (
                              "Absent"
                            ) : item?.timesheetData?.Sunday?.timesheetData[0]
                                ?.timesheet_status == "" ? (
                              <Popover
                                placement="top"
                                content={content}
                                trigger="click"
                              >
                                <FaPlus className="text-sm border-2 p-1 border-black w-[20px] h-[20px] flex justify-center items-center rounded-full cursor-pointer marginAuto" />
                              </Popover>
                            ) : item?.timesheetData?.Sunday?.timesheetData[0]
                                ?.timesheet_status == "Confirmed" ? (
                              <p className="font-medium">
                                {colonTime(
                                  item?.timesheetData?.Sunday?.timesheetData[0]
                                    ?.started
                                )}{" "}
                                -{" "}
                                {colonTime(
                                  item?.timesheetData?.Sunday?.timesheetData[0]
                                    ?.finished
                                )}
                                <span className="text-[#757575] block">
                                  {item?.timesheetData?.Sunday?.timesheetData[0]
                                    ?.spent > 0 &&
                                    item?.timesheetData?.Sunday
                                      ?.timesheetData[0]?.spent}
                                </span>
                              </p>
                            ) : (
                              item?.timesheetData?.Sunday?.timesheetData[0]
                                ?.timesheet_status
                            )}
                          </p>
                        </Popover>
                      </div>
                    )}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-center">
                    {item?.timesheetData?.Monday?.timesheetData?.length == 0 ? (
                      shiftDetails?.extra?.approved != true ? (
                        <div
                          className="flex items-center justify-center text-center"
                          onClick={() =>
                            setSelectedItem({
                              ...selectedItem,
                              item,
                              day: "Monday",
                            })
                          }
                        >
                          <Popover
                            placement="top"
                            content={content}
                            trigger="click"
                          >
                            <FaPlus className="text-sm border-2 p-1 border-black w-[20px] h-[20px] flex justify-center items-center rounded-full cursor-pointer marginAuto" />
                          </Popover>
                        </div>
                      ) : (
                        <GreenPlusIcon />
                      )
                    ) : (
                      <div
                        className="flex items-center justify-center text-center"
                        onClick={() =>
                          setSelectedItem({
                            ...selectedItem,
                            item,
                            day: "Monday",
                          })
                        }
                      >
                        <Popover
                          placement="top"
                          content={
                            shiftDetails?.extra?.approved == true ? "" : content
                          }
                          trigger="click"
                        >
                          <p
                            className={`${
                              shiftDetails?.extra?.approved == true
                                ? "text-[#15803d]"
                                : "cursor-pointer"
                            }`}
                          >
                            {item?.timesheetData?.Monday?.timesheetData[0]
                              ?.is_absence == 1 ? (
                              "Absent"
                            ) : item?.timesheetData?.Monday?.timesheetData[0]
                                ?.timesheet_status == "" ? (
                              <Popover
                                placement="top"
                                content={content}
                                trigger="click"
                              >
                                <FaPlus className="text-sm border-2 p-1 border-black w-[20px] h-[20px] flex justify-center items-center rounded-full cursor-pointer marginAuto" />
                              </Popover>
                            ) : item?.timesheetData?.Monday?.timesheetData[0]
                                ?.timesheet_status == "Confirmed" ? (
                              <p className="font-medium">
                                {colonTime(
                                  item?.timesheetData?.Monday?.timesheetData[0]
                                    ?.started
                                )}{" "}
                                -{" "}
                                {colonTime(
                                  item?.timesheetData?.Monday?.timesheetData[0]
                                    ?.finished
                                )}
                                <span className="text-[#757575] block">
                                  {item?.timesheetData?.Monday?.timesheetData[0]
                                    ?.spent > 0 &&
                                    item?.timesheetData?.Monday
                                      ?.timesheetData[0]?.spent}
                                </span>
                              </p>
                            ) : (
                              item?.timesheetData?.Monday?.timesheetData[0]
                                ?.timesheet_status
                            )}
                          </p>
                        </Popover>
                      </div>
                    )}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-center">
                    {item?.timesheetData?.Tuesday?.timesheetData?.length ==
                    0 ? (
                      shiftDetails?.extra?.approved != true ? (
                        <div
                          className="flex items-center justify-center text-center"
                          onClick={() =>
                            setSelectedItem({
                              ...selectedItem,
                              item,
                              day: "Tuesday",
                            })
                          }
                        >
                          <Popover
                            placement="top"
                            content={content}
                            trigger="click"
                          >
                            <FaPlus className="text-sm border-2 p-1 border-black w-[20px] h-[20px] flex justify-center items-center rounded-full cursor-pointer marginAuto" />
                          </Popover>
                        </div>
                      ) : (
                        <GreenPlusIcon />
                      )
                    ) : (
                      <div
                        className="flex items-center justify-center text-center"
                        onClick={() =>
                          setSelectedItem({
                            ...selectedItem,
                            item,
                            day: "Tuesday",
                          })
                        }
                      >
                        <Popover
                          placement="top"
                          content={
                            shiftDetails?.extra?.approved == true ? "" : content
                          }
                          trigger="click"
                        >
                          <p
                            className={`${
                              shiftDetails?.extra?.approved == true
                                ? "text-[#15803d]"
                                : "cursor-pointer"
                            }`}
                          >
                            {item?.timesheetData?.Tuesday?.timesheetData[0]
                              ?.is_absence == 1 ? (
                              "Absent"
                            ) : item?.timesheetData?.Tuesday?.timesheetData[0]
                                ?.timesheet_status == "" ? (
                              <Popover
                                placement="top"
                                content={content}
                                trigger="click"
                              >
                                <FaPlus className="text-sm border-2 p-1 border-black w-[20px] h-[20px] flex justify-center items-center rounded-full cursor-pointer marginAuto" />
                              </Popover>
                            ) : item?.timesheetData?.Tuesday?.timesheetData[0]
                                ?.timesheet_status == "Confirmed" ? (
                              <p className="font-medium">
                                {colonTime(
                                  item?.timesheetData?.Tuesday?.timesheetData[0]
                                    ?.started
                                )}{" "}
                                -{" "}
                                {colonTime(
                                  item?.timesheetData?.Tuesday?.timesheetData[0]
                                    ?.finished
                                )}
                                <span className="text-[#757575] block">
                                  {item?.timesheetData?.Tuesday
                                    ?.timesheetData[0]?.spent > 0 &&
                                    item?.timesheetData?.Tuesday
                                      ?.timesheetData[0]?.spent}
                                </span>
                              </p>
                            ) : (
                              item?.timesheetData?.Tuesday?.timesheetData[0]
                                ?.timesheet_status
                            )}
                          </p>
                        </Popover>
                      </div>
                    )}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-center text-center">
                    {item?.timesheetData?.Wednesday?.timesheetData?.length ==
                    0 ? (
                      shiftDetails?.extra?.approved != true ? (
                        <div
                          className="flex items-center justify-center text-center"
                          onClick={() =>
                            setSelectedItem({
                              ...selectedItem,
                              item,
                              day: "Wednesday",
                            })
                          }
                        >
                          <Popover
                            placement="top"
                            content={content}
                            trigger="click"
                          >
                            <FaPlus className="text-sm border-2 p-1 border-black w-[20px] h-[20px] flex justify-center items-center rounded-full cursor-pointer marginAuto" />
                          </Popover>
                        </div>
                      ) : (
                        <GreenPlusIcon />
                      )
                    ) : (
                      <div
                        className="flex items-center justify-center text-center"
                        onClick={() =>
                          setSelectedItem({
                            ...selectedItem,
                            item,
                            day: "Wednesday",
                          })
                        }
                      >
                        <Popover
                          placement="top"
                          content={
                            shiftDetails?.extra?.approved == true ? "" : content
                          }
                          trigger="click"
                        >
                          <p
                            className={`${
                              shiftDetails?.extra?.approved == true
                                ? "text-[#15803d]"
                                : "cursor-pointer"
                            }`}
                          >
                            {item?.timesheetData?.Wednesday?.timesheetData[0]
                              ?.is_absence == 1 ? (
                              "Absent"
                            ) : item?.timesheetData?.Wednesday?.timesheetData[0]
                                ?.timesheet_status == "" ? (
                              <Popover
                                placement="top"
                                content={content}
                                trigger="click"
                              >
                                <FaPlus className="text-sm border-2 p-1 border-black w-[20px] h-[20px] flex justify-center items-center rounded-full cursor-pointer marginAuto" />
                              </Popover>
                            ) : item?.timesheetData?.Wednesday?.timesheetData[0]
                                ?.timesheet_status == "Confirmed" ? (
                              <p className="font-medium">
                                {colonTime(
                                  item?.timesheetData?.Wednesday
                                    ?.timesheetData[0]?.started
                                )}{" "}
                                -{" "}
                                {colonTime(
                                  item?.timesheetData?.Wednesday
                                    ?.timesheetData[0]?.finished
                                )}
                                <span className="text-[#757575] block">
                                  {item?.timesheetData?.Wednesday
                                    ?.timesheetData[0]?.spent > 0 &&
                                    item?.timesheetData?.Wednesday
                                      ?.timesheetData[0]?.spent}
                                </span>
                              </p>
                            ) : (
                              item?.timesheetData?.Wednesday?.timesheetData[0]
                                ?.timesheet_status
                            )}
                          </p>
                        </Popover>
                      </div>
                    )}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-center text-center">
                    {item?.timesheetData?.Thursday?.timesheetData?.length ==
                    0 ? (
                      shiftDetails?.extra?.approved != true ? (
                        <div
                          className="flex items-center justify-center text-center"
                          onClick={() =>
                            setSelectedItem({
                              ...selectedItem,
                              item,
                              day: "Thursday",
                            })
                          }
                        >
                          <Popover
                            placement="top"
                            content={content}
                            trigger="click"
                          >
                            <FaPlus className="text-sm border-2 p-1 border-black w-[20px] h-[20px] flex justify-center items-center rounded-full cursor-pointer marginAuto" />
                          </Popover>
                        </div>
                      ) : (
                        <GreenPlusIcon />
                      )
                    ) : (
                      <div
                        className="flex items-center justify-center text-center"
                        onClick={() =>
                          setSelectedItem({
                            ...selectedItem,
                            item,
                            day: "Thursday",
                          })
                        }
                      >
                        <Popover
                          placement="top"
                          content={
                            shiftDetails?.extra?.approved == true ? "" : content
                          }
                          trigger="click"
                        >
                          <p
                            className={`${
                              shiftDetails?.extra?.approved == true
                                ? "text-[#15803d]"
                                : "cursor-pointer"
                            }`}
                          >
                            {item?.timesheetData?.Thursday?.timesheetData[0]
                              ?.is_absence == 1 ? (
                              "Absent"
                            ) : item?.timesheetData?.Thursday?.timesheetData[0]
                                ?.timesheet_status == "" ? (
                              <Popover
                                placement="top"
                                content={content}
                                trigger="click"
                              >
                                <FaPlus className="text-sm border-2 p-1 border-black w-[20px] h-[20px] flex justify-center items-center rounded-full cursor-pointer marginAuto" />
                              </Popover>
                            ) : item?.timesheetData?.Thursday?.timesheetData[0]
                                ?.timesheet_status == "Confirmed" ? (
                              <p className="font-medium">
                                {colonTime(
                                  item?.timesheetData?.Thursday
                                    ?.timesheetData[0]?.started
                                )}{" "}
                                -{" "}
                                {colonTime(
                                  item?.timesheetData?.Thursday
                                    ?.timesheetData[0]?.finished
                                )}
                                <span className="text-[#757575] block">
                                  {item?.timesheetData?.Thursday
                                    ?.timesheetData[0]?.spent > 0 &&
                                    item?.timesheetData?.Thursday
                                      ?.timesheetData[0]?.spent}
                                </span>
                              </p>
                            ) : (
                              item?.timesheetData?.Thursday?.timesheetData[0]
                                ?.timesheet_status
                            )}
                          </p>
                        </Popover>
                      </div>
                    )}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-center text-center">
                    {item?.timesheetData?.Friday?.timesheetData?.length == 0 ? (
                      shiftDetails?.extra?.approved != true ? (
                        <div
                          className="flex items-center justify-center text-center"
                          onClick={() =>
                            setSelectedItem({
                              ...selectedItem,
                              item,
                              day: "Friday",
                            })
                          }
                        >
                          <Popover
                            placement="top"
                            content={content}
                            trigger="click"
                          >
                            <FaPlus className="text-sm border-2 p-1 border-black w-[20px] h-[20px] flex justify-center items-center rounded-full cursor-pointer marginAuto" />
                          </Popover>
                        </div>
                      ) : (
                        <GreenPlusIcon />
                      )
                    ) : (
                      <div
                        className="flex items-center justify-center text-center"
                        onClick={() =>
                          setSelectedItem({
                            ...selectedItem,
                            item,
                            day: "Friday",
                          })
                        }
                      >
                        <Popover
                          placement="top"
                          content={
                            shiftDetails?.extra?.approved == true ? "" : content
                          }
                          trigger="click"
                        >
                          <p
                            className={`${
                              shiftDetails?.extra?.approved == true
                                ? "text-[#15803d]"
                                : "cursor-pointer"
                            }`}
                          >
                            {item?.timesheetData?.Friday?.timesheetData[0]
                              ?.is_absence == 1 ? (
                              "Absent"
                            ) : item?.timesheetData?.Friday?.timesheetData[0]
                                ?.timesheet_status == "" ? (
                              <Popover
                                placement="top"
                                content={content}
                                trigger="click"
                              >
                                <FaPlus className="text-sm border-2 p-1 border-black w-[20px] h-[20px] flex justify-center items-center rounded-full cursor-pointer marginAuto" />
                              </Popover>
                            ) : item?.timesheetData?.Friday?.timesheetData[0]
                                ?.timesheet_status == "Confirmed" ? (
                              <p className="font-medium">
                                {colonTime(
                                  item?.timesheetData?.Friday?.timesheetData[0]
                                    ?.started
                                )}{" "}
                                -{" "}
                                {colonTime(
                                  item?.timesheetData?.Friday?.timesheetData[0]
                                    ?.finished
                                )}
                                <span className="text-[#757575] block">
                                  {item?.timesheetData?.Friday?.timesheetData[0]
                                    ?.spent > 0 &&
                                    item?.timesheetData?.Friday
                                      ?.timesheetData[0]?.spent}
                                </span>
                              </p>
                            ) : (
                              item?.timesheetData?.Friday?.timesheetData[0]
                                ?.timesheet_status
                            )}
                          </p>
                        </Popover>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {absenteModal && (
        <AbsentModal
          onCloseAbsentModal={onCloseAbsentModal}
          openAbsentModal={absenteModal}
          absenseType={absenseType}
          worksite={worksite}
          setWorksite={setWorksite}
          selectedItem={selectedItem}
          workStatus={"Rostered"}
          isShift={true}
          fetchShiftDetails={fetchShiftDetails}
        />
      )}
      {assignTaskModal && (
        <CreatingWorkShiftModal
          assignTaskModal={assignTaskModal}
          closeAssignTaskWorker={closeAssignTaskWorker}
          selectedItem={selectedItem}
          isComplted={false}
          isShift={true}
          fetchShiftDetails={fetchShiftDetails}
        />
      )}
      {approveModal && (
        <ApproveModal
          onCloseApproveModal={onCloseApproveModal}
          openApproveModal={approveModal}
          approveWorker={approveWorker}
          isLoading={isLoading}
        />
      )}
      {inductionPending && (
        <div className="modal">
          <div className="modal-content">
            <div className="text-right">
              <button
                className="close-modal"
                onClick={() => setInductionPending(false)}
              >
                <FaTimes />
              </button>
            </div>

            <div className="modal-body mt-2">
              <h2 className="text-xl font-bold mb-3">Induction in progress</h2>
              <p className="text-[#545454] text-[17px]">
                {selectedWorker} is currently being inducted.
              </p>
              <p className="text-[red]">
                Creating this shift stop their induction, are you sure you to
                continue?
              </p>
              <div className="pt-4">
                <Button
                  className="float-right btn btn-blue w-1/5 bg-blue-disable"
                  onClick={overRideWorker}
                  // disabled={data?.selectedOption == 0}
                >
                  {isLoadingOverride ? <ButtonSpinner /> : "Continue"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {payrollpending && (
        <div className="modal">
          <div className="modal-content">
            <div className="text-right">
              <button
                className="close-modal"
                onClick={() => setPayrollPending(false)}
              >
                <FaTimes />
              </button>
            </div>

            <div className="modal-body mt-2">
              <h2 className="text-xl font-bold mb-4">No Rates Available</h2>
              <p className="text-[#545454] text-[18px] mb-4">
                DOCKLANDS - {selectedWorker}{" "}
              </p>
              <p className="text-[#545454]">
                Before a shift can be created for Workers, you will need to
                assign at least one payroll category to Worksite
              </p>
              <div className="pt-4">
                <Button
                  className="float-right btn btn-blue w-1/5 bg-blue-disable"
                  // onClick={overRideWorker}
                  // disabled={data?.selectedOption == 0}
                >
                  {isLoadingOverride ? <ButtonSpinner /> : "Assign"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {workDayPending && (
        <div className="modal">
          <div className="modal-content">
            <div className="text-right">
              <button
                className="close-modal"
                onClick={() => setWorkDayPending(false)}
              >
                <FaTimes />
              </button>
            </div>

            <div className="modal-body mt-2">
              <h2 className="text-xl font-bold mb-4">
                Select {selectedWorker} Worker Rate
              </h2>
              <p className="text-[#A1A1A1]">
                This worker has not been assigned a pay rate on Worksite name
                yet. Please assign it now by selecting a rate below:
              </p>
              <Select
                defaultValue="lucy"
                onChange={handleChange}
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                  { value: "Yiminghe", label: "yiminghe" },
                  { value: "disabled", label: "Disabled", disabled: true },
                ]}
              />
              <div className="pt-4">
                <Button
                  className="float-right btn btn-blue w-1/5 bg-blue-disable"
                  // onClick={overRideWorker}
                  // disabled={data?.selectedOption == 0}
                >
                  {isLoadingOverride ? <ButtonSpinner /> : "Assign"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {empStatus && (
        <div className="modal">
          <div className="modal-content">
            <div className="text-right">
              <button
                className="close-modal"
                onClick={() => setEmpStatus(false)}
              >
                <FaTimes />
              </button>
            </div>

            <div className="modal-body mt-2">
              <h2 className="text-xl font-bold mb-4">
                No Casual Rates Available
              </h2>
              <div className="mb-4">
                <p className="text-[#545454] mb-2 text-[20px]">
                  {dataStore?.name ? dataStore?.name : dataStore?.jobName}
                </p>
                <p className="text-[#545454] mb-2">
                  Before a shift can be created for Workers, you will need to
                  assign at least one Casual rate
                </p>
              </div>
              <div className="pt-4 flex justify-center">
                <Button
                  className="btn btn-blue w-1/5 bg-blue-disable"
                  onClick={openPayrollModal}
                >
                  Assign
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {payrollModal && (
        <div className="modal payroll">
          <div className="modal-content">
            <div className="text-right">
              <button className="close-modal" onClick={closePayrollModal}>
                <FaTimes />
              </button>
            </div>

            <div className="modal-body mt-2">
              <h2 className="text-xl font-bold mb-4">
                Select payroll category
              </h2>
              <table className="w-full">
                <tbody className="bg-white">
                  {payrollData?.data?.map((item, index) => (
                    <tr key={index} className="border-b border-[#F0F0F0]">
                      <td>{item?.name}</td>
                      <td>
                        <Checkbox onChange={(e) => checkPayroll(e, item)} />
                        {/* <input type="radio" name="payroll" onChange={(e) => checkPayroll(e, item)} /> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="pt-4">
                <Button
                  className="float-right btn btn-blue w-1/5 bg-blue-disable"
                  onClick={assignPayroll}
                  // disabled={selectedTask?.length == 0}
                >
                  {isLoadingOverride ? <ButtonSpinner /> : "Assign"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {payrollPendingData && (
        <div className="modal">
          <div className="modal-content">
            <div className="text-right">
              <button
                className="close-modal"
                onClick={() => setPayrollPendingData(false)}
              >
                <FaTimes />
              </button>
            </div>

            <div className="modal-body mt-2">
              <h2 className="text-xl font-bold mb-4">
                No{" "}
                {selectedWorkerItem?.map((worker, i) => worker.name).join(", ")}{" "}
                worker rate{" "}
              </h2>
              {selectedWorkerItem?.map((worker, i) => (
                <div key={i} className="mb-4">
                  <p className="text-[#545454] mb-2">
                    This worker has not been assigned a pay rate on{" "}
                    {dataStore?.name ? dataStore?.name : dataStore?.jobName}{" "}
                    yet. Please assign it now by selecting a rate below:
                  </p>
                  <div className="flex justify-end">
                    {/* <Button
                    className="btn btn-blue w-1/5 bg-blue-disable"
                    onClick={() => { openPayrollModal(worker?._id); setSelectedWorkerId(worker?._id) }}
                    disabled={assignedWorker?.includes(worker?._id)}
                  >
                    {assignedWorker?.includes(worker?._id) ? 'Assigned' : 'Assign'}
                  </Button> */}
                    <Select
                      placeholder="Select payroll category"
                      className="w-full"
                      onChange={(e) =>
                        handleChangePayrollArr(e, i, worker?._id)
                      }
                      onClick={() =>
                        openPayrollCategory(
                          worker?.employment_status_id,
                          worker?._id
                        )
                      }
                    >
                      {payrollDataArr?.map((item) => (
                        <Select.Option value={item?.id}>
                          {item?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>
              ))}
              <div className="pt-4 flex justify-center">
                <Button
                  className="btn btn-blue w-1/5 bg-blue-disable"
                  onClick={assignPayrollWorkerPayroll}
                >
                  Assign
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {empStatePending && (
        <div className="modal">
          <div className="modal-content">
            <div className="text-right">
              <button
                className="close-modal"
                onClick={() => setEmpDayPending(false)}
              >
                <FaTimes />
              </button>
            </div>

            <div className="modal-body mt-2">
              {/* <h2 className="text-xl font-bold mb-4">No {selectedWorkerItem?.map((worker, i) => worker.name).join(', ')} Available </h2> */}
              {selectedWorkerItem?.map((worker, i) => (
                <div key={i} className="mb-4">
                  <p className="text-[#545454] mb-2 text-[20px]">
                    {dataStore?.name ? dataStore?.name : dataStore?.jobName} -{" "}
                    {worker.name}
                  </p>
                  <p className="text-[#545454] mb-2">
                    Before a shift can be created for Workers, you will need to
                    assign following rates
                  </p>
                  <label className="pt-4 font-bold">
                    {worker?.employment_status}
                  </label>
                  <div className="flex justify-end">
                    {/* <Button
                    className="btn btn-blue w-1/5 bg-blue-disable"
                    onClick={() => { openPayrollModal(worker?._id); setSelectedWorkerId(worker?._id) }}
                    disabled={assignedWorker?.includes(worker?._id)}
                  >
                    {assignedWorker?.includes(worker?._id) ? 'Assigned' : 'Assign'}
                  </Button> */}
                    <Select
                      placeholder="Select payroll category"
                      className="w-full"
                      onChange={(e) =>
                        handleChangePayrollArr(e, i, worker?._id)
                      }
                      onClick={() =>
                        openPayrollCategory(
                          worker?.employment_status_id,
                          worker?._id
                        )
                      }
                    >
                      {payrollDataArr?.map((item) => (
                        <Select.Option value={item?.id}>
                          {item?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </div>
              ))}
              <div className="pt-4 flex justify-center">
                <Button
                  className="btn btn-blue w-1/5 bg-blue-disable"
                  onClick={assignPayrollWorker}
                >
                  Assign
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShiftDetailsTable;
