import React from "react";
import { Input, Modal, Select, DatePicker } from "antd";
import { Button } from "antd";
import { Controller, useForm } from "react-hook-form";
import InputError from "../../../../components/InputError";
import moment from "moment";
import { useState, useEffect } from "react";
const UnionFeesModal = ({ visible, onCancel, isEditing, editData, onSave }) => {
  const modalTitle = isEditing ? "Editing Union Fees" : "Creating Union Fees";
  const { control, handleSubmit, reset } = useForm({
    defaultValues: isEditing ? editData : {},
  });

  useEffect(() => {
    if (visible && isEditing) {
      reset(editData);
    } else {
      reset();
    }
  }, [visible, isEditing, reset, editData]);

  const onSubmit = (data) => {
    reset();
    onSave(data);
  };

  return (
    <Modal
      title={modalTitle}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="item mt-5">
          <label htmlFor="name">Name</label>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <Input {...field} type="text" className="mt-3" />
            )}
          />
        </div>
        <div className="item mt-5">
          <label htmlFor="rate">Rate</label>
          <Controller
            control={control}
            name="rate"
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                className="mt-3"
                id="rate"
                min="0"
              />
            )}
          />
        </div>
        <div className="item mt-5">
          <label htmlFor="frequency">Frequency</label>
          <Controller
            control={control}
            name="frequency"
            render={({ field }) => (
              <Select {...field} className="mt-3 w-full">
                <Select.Option value="1">Per Week</Select.Option>
              </Select>
            )}
          />
        </div>
        <div className="mt-5 flex justify-between items-center">
          <div>
            <label htmlFor="from">From</label>
            <Controller
              control={control}
              name="from"
              render={({ field }) => (
                <Input {...field} type="date" className="mt-3" id="from" />
              )}
            />
          </div>
          <div>
            <label htmlFor="to">To</label>
            <Controller
              control={control}
              name="to"
              render={({ field }) => (
                <Input {...field} type="date" className="mt-3" id="to" />
              )}
            />
          </div>
        </div>
        <div className="item mt-5 text-center">
          <Button htmlType="submit" className="btn btn-blue">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default UnionFeesModal;
