import React, { useState } from "react";
import LogCauseModal from "../Modal/LogCauseModal";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowDown, FaArrowUp, FaTrash } from "react-icons/fa";
import moment from "moment";

const TimesheetTable = ({ data }) => {
  const { id } = useParams();

  const [visibleModal, setVisibleModal] = useState(false);
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState([]);

  const select = (index) => {
    let allIndex = isOpen?.length > 0 ? [...isOpen] : [];

    if (allIndex?.includes(index)) {
      allIndex?.splice(allIndex?.indexOf(index), 1);
      setIsOpen(allIndex);
    } else {
      allIndex?.push(index);
      setIsOpen(allIndex);
    }
  };

  return (
    <>
      {visibleModal && <LogCauseModal setVisibleModal={setVisibleModal} />}
      <div className="p-2 bg-[#f5f5f5]">
        {data?.data?.map((tdata, tindex) => {
          return (
            <div className="bg-white p-4 my-4">
              <div className="flex justify-between">
                <div className="text-xl">{tdata?.week}</div>
                <div>
                  <div
                    className="bg-orange-500 p-2 rounded-md text-white ml-3"
                    onClick={() => select(tindex)}
                  >
                    {isOpen?.includes?.(tindex) ? (
                      <FaArrowUp />
                    ) : (
                      <FaArrowDown />
                    )}
                  </div>
                </div>
              </div>
              <div className="pt-4">
                {isOpen?.includes?.(tindex) && (
                  <table className="w-full">
                    <thead className="bg-[#E8F3FF] text-center">
                      <tr className="text-left">
                        <th className=" px-4 py-3 ">
                          <div className="flex items-center justify-center">
                            Day
                          </div>
                        </th>
                        <th className=" px-4 py-3 ">
                          <div className="flex items-center justify-center">
                            Date
                          </div>
                        </th>
                        <th className=" px-4 py-3 ">
                          <div className="flex items-center justify-center">
                            Workers
                          </div>
                        </th>
                        <th className=" px-4 py-3 ">
                          <div className="flex items-center justify-center">
                            Hours
                          </div>
                        </th>
                        <th className=" px-4 py-3 ">
                          <div className="flex items-center justify-center">
                            Tasks
                          </div>
                        </th>
                        <th className=" px-4 py-3 ">
                          <div className="flex items-center justify-center">
                            $
                          </div>
                        </th>
                        <th className=" px-4 py-3 ">
                          <div className="flex items-center justify-center">
                            Actions
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white w-full">
                      {tdata?.data?.map((item, index) => (
                        <tr key={index} className="border-b border-[#F0F0F0]">
                          <td className="px-4 py-3 text-center">
                            <div className="flex items-center justify-center">
                              {item?.day}
                            </div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="flex items-center justify-center">
                              {moment?.(item?.created_at)?.format("DD-MM-YYYY")}
                            </div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="flex items-center justify-center">
                              {item?.workers}
                            </div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="flex items-center justify-center">
                              {parseFloat?.(item?.hours || 0)?.toFixed(2)}
                            </div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="flex items-center justify-center">
                              {item?.tasks}
                            </div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="flex items-center justify-center">
                              {item?.amount}
                            </div>
                          </td>
                          <td className="px-4 py-3">
                            <div className="flex items-center justify-center">
                              <div>
                                <button
                                  onClick={() => {
                                    navigate(
                                      `/admin/worksite/details/timesheet/${id}/${item?._id}/timesheet-details`
                                    );
                                  }}
                                  className="px-3 py-1 text-sm text-blue-700 underline rounded-md"
                                >
                                  Details
                                </button>
                              </div>
                              <div>
                                <FaTrash />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TimesheetTable;
