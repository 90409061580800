import React from "react";
import { Modal, Select, Input } from "antd";
import { Button } from "antd";
import { DatePicker } from "antd";
import { useForm, Controller } from "react-hook-form";
import { useEffect } from "react";
import InputError from "../../../../components/InputError";
import { useState } from "react";
const PayrollTaxModal = ({
  visible,
  onCancel,
  isEditing,
  editData,
  onSave,
}) => {
  const [selectedDate, setSelectedDate] = useState();
  const [selectedDate2, setSelectedDate2] = useState();
  const modalTitle = isEditing ? "Editing PayrollTax" : "Creating PayrollTax";
  const { control, handleSubmit, reset } = useForm({
    defaultValues: isEditing ? editData : {}, // Use an empty object for new entries
  });

  useEffect(() => {
    // Reset the form with new initial values when visible and editing
    if (visible && isEditing) {
      reset(editData);
    } else {
      reset(); // Reset the form without preloading data when creating new
    }
  }, [visible, isEditing, reset, editData]);

  const onSubmit = (data) => {
    console.log("the dhd", data);
    reset();

    onSave(data); // Pass the form data to the onSave function in Misc
  };

  return (
    <Modal
      title={modalTitle}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="item mt-5">
          <label htmlFor="name">Payroll Tax Name</label>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input type="text" className="mt-3" {...field} />
            )}
          />
        </div>
        <div className="item mt-5">
          <label htmlFor="summary_column">Summary Column</label>
          <Controller
            name="summary_column"
            control={control}
            render={({ field }) => (
              <Input type="text" className="mt-3" {...field} />
            )}
          />
        </div>
        <div className="item mt-5">
          <label htmlFor="rate">Rate</label>
          <Controller
            name="rate"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                className="mt-3"
                id="rate"
                min="0"
              />
            )}
          />
        </div>
        <div className="item mt-5">
          <label htmlFor="frequency">Frequency</label>
          <Controller
            name="frequency"
            control={control}
            render={({ field }) => (
              <Select className="mt-3 w-full" {...field}>
                <Select.Option value="1">Per Week</Select.Option>
              </Select>
            )}
          />
        </div>
        <div className="mt-5 flex justify-between items-center">
          <div>
            <label htmlFor="from">From</label>
            <Controller
              name="from"
              control={control}
              render={({ field }) => (
                <Input {...field} type="date" className="mt-3" id="from" />
              )}
            />
          </div>
          <div>
            <label htmlFor="to">To</label>
            <Controller
              name="to"
              control={control}
              render={({ field }) => (
                <Input {...field} type="date" className="mt-3" id="to" />
              )}
            />
          </div>
        </div>
        <h1 className="mt-4">
          Total weekly payroll tax of 50 is chosen for 4 weeks.
        </h1>
        <div className="item mt-5 justify-between flex">
          <div className=" mt-3">
            <Controller
              name="export_to_summary"
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <input type="checkbox" id="export_to_summary" {...field} />
              )}
            />
            <label htmlFor="export_to_summary" className="ml-2">
              Export to Summary
            </label>
          </div>
          <Button htmlType="submit" className="btn btn-blue">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default PayrollTaxModal;
