import { Button, Checkbox, Dropdown, Select } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { FaAngleUp, FaPlus, FaSearch } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { GetShift } from "../../../../../helper/worksite/worksiteDetails.js/timesheet";
import AreasDefaultSvg from "../../../../../assets/svg/areasDefault";
import { TableDefaultPage } from "../../../../../components/default/defaultPage";
import { ButtonSpinner } from "../../../../../components/spinners";
import InfiniteScroll from "react-infinite-scroll-component";
import { TextShimmer } from "../../../../../components/shimmer/shimmer";
import moment from "moment";
import { GetAreas } from "../../../../../helper/worksite/worksiteDetails.js/areas";
import { NoSearchTableSvg } from "../../../../../assets/svg/tableDefaul";
import { NoTableOption } from "../../../../../components/noOption/noOption";

const data = [
  {
    name: "Charlie Weideman",
    hours: "06:30-14:30",
    totalHours: "(8hr)",
    work: "1 PRE-HSR(4hr) 0VAR-So-L(4hr)",
    dollar: "452.37",
    break: "Yes",
    status: "Completed",
  },
];
const TimesheetDetailsSubsection = ({ activeDate, setActiveDate }) => {
  const { id } = useParams();
  const { timesheet } = useParams();
  const navigate = useNavigate();
  console.log("the active date is ", activeDate);
  function onAdd() {
    navigate(`/admin/worksite/add-timesheet-details/${id}/${timesheet}`);
  }

  const onEdit = (shift, activeDate) => {
    navigate(
      `/admin/worksite/edit-timesheet-details/${id}/${timesheet}/${shift}?date=${activeDate}`
    );
  };

  const initialShift = {
    data: [],
    extra: {},
    search: "",
    isLoading: false,
    isShimmer: true,
  };

  const initialAreaOpt = {
    options: [],
    slected: [],
    search: "",
    selectAll: false,
  };

  const [shift, setShift] = useState(initialShift);
  const [areaOpt, setAreaOpt] = useState(initialAreaOpt);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  let deafaulPage = {
    disclaimer: "No data available for shift details",
    description: "There are no shift details display.",
    buttonText: "Create Shift Details",
    svg: <AreasDefaultSvg />,
    redirect: `/admin/worksite/add-timesheet-details/${id}/${timesheet}`,
  };

  function toggleDropdown() {
    setDropdownOpen((prev) => !prev);
  }

  function AreaCheck() {
    return (
      <div
        className="min-w-[25vw] max-h-[35vh] bg-white p-2 rounded-lg drop-shadow-lg shadow-lg"
        onBlur={toggleDropdown}
        animation={false}
      >
        <span className="flex justify-between p-2">
          <p>Area Name</p>
          <p>
            Select All{" "}
            <Checkbox
              checked={areaOpt?.selectAll}
              onChange={handleShift?.selectAll}
            />
          </p>
        </span>
        <hr className="border-[#F5F5F5] border-b-4" />
        <div className="max-h-[20vh] overflow-y-scroll">
          {areaOpt?.options?.map((area, index) => (
            <div
              key={area?._id}
              className="flex justify-between p-2 border-b border-[#F5F5F5]"
            >
              <div>
                <label htmlFor={area?._id} key={area?._id}>
                  {area?.abbreviation}&nbsp;{area?.name}
                </label>
              </div>
              <div>
                <Checkbox
                  id={area?._id}
                  key={area?._id}
                  checked={areaOpt?.slected?.includes(area?._id)}
                  onChange={(e) => handleShift?.slectedAreas(e, area?._id)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  const handleShift = {
    selectAll: async (e, id) => {
      if (e?.target?.checked) {
        let allId = areaOpt?.options?.length > 0 ? [...areaOpt?.options] : [];
        let allSelect = [];

        allId?.map((area, index) => {
          allSelect?.push(area?._id);
        });

        setAreaOpt({
          ...areaOpt,
          slected: allSelect,
          selectAll: true,
        });

        const params = {
          area_ids: [],
        };
        let data = await handleShift?.get(params);
      } else {
        setAreaOpt({
          ...areaOpt,
          selectAll: false,
          slected: [],
        });
        const params = {
          area_ids: [],
        };
        let data = await handleShift?.get(params);
      }
    },
    slectedAreas: async (e, id) => {
      console.log(e?.target?.checked, id, "e, id");
      if (e?.target?.checked) {
        let allId = areaOpt?.slected?.length > 0 ? [...areaOpt?.slected] : [];
        allId?.push(id);
        setAreaOpt({
          ...areaOpt,
          slected: allId,
        });

        const params = {
          area_ids: allId,
        };
        let data = await handleShift?.get(params);
      } else {
        let allId = [...areaOpt?.slected];
        allId?.splice(areaOpt?.selectArea?.indexOf(id), 1);
        setAreaOpt({
          ...areaOpt,
          slected: allId,
        });

        const params = {
          area_ids: allId,
        };
        let data = await handleShift?.get(params);
      }
    },
    searchAreas: async (e) => {
      const params = {
        search: e?.target?.value,
      };
      let data = await handleShift?.getAreas(params);
    },
    getAreas: async (areaData) => {
      const params = {
        worksite_id: id,
        search: areaData?.search,
      };
      let data = await GetAreas(params);
      if (data?.status) {
        console.log(data, "areaData");
        setAreaOpt({
          ...areaOpt,
          options: [...data?.data?.data],
          search: params?.search,
        });
      }
    },
    search: async (e) => {
      const params = {
        ...shift,
        worksite_timesheet_id: timesheet,
        search: e?.target?.value,
      };

      let data = await handleShift?.get(params);
    },
    fetch: async () => {
      const params = {
        ...shift,
        worksheet_id: timesheet,
        cursor: shift?.extra?.cursor,
      };

      let data = await handleShift?.get(params);
    },
    get: async (getShift) => {
      const params = {
        id: getShift?.id,
        worksite_timesheet_id: timesheet,
        worksite_id: id,
        area_ids: getShift?.area_ids?.toString(),
        cursor: getShift?.cursor,
        take: getShift?.take,
        previous: getShift?.previous,
        search: getShift?.search,
        date: activeDate,
      };
      let data = await GetShift(params);
      if (data?.status) {
        setShift({
          ...shift,
          data: [...data?.data?.data],
          extra: { ...data?.extra },
          search: getShift?.search,
          isLoading: false,
          isShimmer: false,
        });

        console.log(data?.extra?.timeSheetData?.created_at);

        let date = moment?.(data?.extra?.timeSheetData?.created_at)?.format(
          "YYYY-MM-DD"
        );

        console.log(date, "date");
        setActiveDate(date);
        return data;
      } else {
        return data;
      }
    },
  };

  let noSearchOption = {
    svg: <NoSearchTableSvg />,
    disclaimer: "There were no search results found",
    description: "Sorry, No Item Matches Your Search Term.",
  };

  useEffect(() => {
    (async () => {
      const params = {
        worksite_timesheet_id: timesheet,
        cursor: "",
        take: "",
        previous: "",
        search: "",
      };
      // navigate(`/admin/worksite/add-timesheet-details/${id}/${timesheet}`);
      let data = await handleShift?.get(params);
    })();
  }, [activeDate]);

  return (
    <div>
      {shift?.isShimmer ? (
        <div>
          <TextShimmer data={{ gap: 15, line: 20 }} />
        </div>
      ) : (
        <div>
          <div className="flex justify-between items-center gap-3 my-2">
            <div className="flex gap-3 items-center">
              <div className="flex items-center border border-[#E9E9E9] rounded-md p-2 px-4">
                <FaSearch className="fill-[#A1A1A1]" />
                <input
                  placeholder="Search by Name"
                  className=" text-sm px-4"
                  autoComplete="off"
                  onChange={handleShift?.search}
                />
              </div>

              <div className="flex flex-shrink-0 rounded-md h-fit">
                <div className="border border-gray-200 rounded-md border-black text-center">
                  <Dropdown
                    trigger={["click"]}
                    placement="bottomRight"
                    dropdownRender={(menu) => (
                      <div className="bg-white">
                        <div className="p-2">
                          <div className="border border-[#000] p-2 rounded-md w-full flex justify-start items-center gap-3">
                            <FaSearch className="scale-90 text-gray-400" />
                            <input
                              type="text"
                              placeholder="Search by Area"
                              className="w-full border-none outline-none text-sm"
                              // value={areaOpt?.search}
                              onChange={handleShift?.searchAreas}
                            />
                          </div>
                        </div>
                        <AreaCheck />
                      </div>
                    )}
                    className="bg-white rounded-lg hover:bg-white"
                  >
                    <div
                      className="px-12 py-2 cursor-pointer"
                      onClick={handleShift?.getAreas}
                    >
                      All area
                    </div>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <Button
                className="border border-[#E9E9E9] h-[42px] px-6"
                onClick={() => navigate("/timesheet-summary-view/:id")}
              >
                View In Summary
              </Button>
              <Button
                className="flex justify-center items-center py-3 border border-[#111111] h-[42px]"
                onClick={onAdd}
              >
                Add New
                <div className="bg-orange-500 p-2 rounded-md text-white ml-3">
                  <FaPlus />
                </div>
              </Button>
            </div>
          </div>

          {shift?.data?.length <= 0 &&
          !shift?.search &&
          areaOpt?.slected?.length <= 0 ? (
            <TableDefaultPage data={deafaulPage} />
          ) : (
            <>
              {(shift?.data?.length <= 0 && shift?.search) ||
              (shift?.data?.length <= 0 && areaOpt?.slected?.length > 0) ? (
                <NoTableOption data={noSearchOption} />
              ) : (
                <Fragment>
                  <div id="scrollableDiv" className="h-[400]">
                    <InfiniteScroll
                      className=""
                      dataLength={shift?.data?.length}
                      next={handleShift?.fetch}
                      hasMore={shift?.data?.length != shift?.extra?.totalItems}
                      loader={
                        <div>
                          <ButtonSpinner />
                        </div>
                      }
                      height={400}
                      endMessage={<div></div>}
                      scrollableTarget="scrollableDiv"
                    >
                      <table className="w-full">
                        <thead className="bg-[#E8F3FF] text-center">
                          <tr className="text-left">
                            <th className=" px-4 py-3 ">
                              <div className="flex items-center justify-center">
                                Name
                              </div>
                            </th>
                            <th className=" px-4 py-3 ">
                              <div className="flex items-center justify-center">
                                Shift Hours
                              </div>
                            </th>
                            <th className=" px-4 py-3 ">
                              <div className="flex items-center justify-center">
                                Work
                              </div>
                            </th>
                            <th className=" px-4 py-3 ">
                              <div className="flex items-center justify-center">
                                $
                              </div>
                            </th>
                            <th className=" px-4 py-3 ">
                              <div className="flex items-center justify-center">
                                Break
                              </div>
                            </th>
                            <th className=" px-4 py-3 ">
                              <div className="flex items-center justify-center">
                                Status
                              </div>
                            </th>
                            <th className=" px-4 py-3 ">
                              <div className="flex items-center justify-center">
                                Actions
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white">
                          {shift?.data.map((item, index) => (
                            <tr
                              key={index}
                              className="border-b py-4 border-[#F0F0F0]"
                            >
                              <td className="px-4 py-3 text-center">
                                <div className="flex items-center justify-center">
                                  {item?.users?.[0]?.name
                                    ? item.users[0].name
                                    : ""}
                                </div>
                              </td>
                              <td className="px-4 py-3">
                                <div className="flex items-center justify-center">
                                  <p> {item?.started + "-" + item?.finished}</p>{" "}
                                  <p className="text-[#757575]">
                                    &nbsp; (
                                    {(
                                      parseFloat(item?.finished) -
                                      parseFloat(item?.started)
                                    ).toFixed(2)}
                                    )
                                  </p>
                                </div>
                              </td>
                              <td className="px-4 py-3 ">
                                <div className="items-center justify-center">
                                  {item?.works?.map((work, ind) => {
                                    return (
                                      <>
                                        <div>
                                          <span>
                                            {work
                                              ?.hourly_rate_tasks_estimations?.[0]
                                              ?.hourly_rate_task?.[0]
                                              ?.abbreviation || ""}{" "}
                                            {work
                                              ?.hourly_rate_tasks_estimations?.[0]
                                              ?.hourly_rate_task?.[0]?.name ||
                                              ""}
                                          </span>{" "}
                                          <span className="text-[#757575]">
                                            ({work?.spent})
                                          </span>
                                          {ind > 0 && (
                                            <span>&nbsp;,&nbsp;</span>
                                          )}
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </td>
                              <td className="px-4 py-3">
                                <div className="flex items-center justify-center">
                                  {item.amount.toFixed(2)}
                                </div>
                              </td>
                              <td className="px-4 py-3">
                                <div className="flex items-center justify-center">
                                  {item?.break}
                                </div>
                              </td>
                              <td className="px-4 py-3">
                                <div className="flex items-center justify-center">
                                  {item?.timesheet_status}
                                </div>
                              </td>
                              <td className="px-4 py-3">
                                <div className="flex items-center justify-center">
                                  <FiEdit
                                    className="cursor-pointer"
                                    onClick={() => onEdit(item?._id)}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </InfiniteScroll>
                  </div>
                </Fragment>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default TimesheetDetailsSubsection;
