import React from "react";
import { FaEdit, FaTrashAlt, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import DeleteAgreementModal from "../Modal/DeleAgreementModal";
import { Archive } from "../../assets/svg";
import { useParams } from "react-router-dom";
const AgreementTable = ({
  data,
  setData,
  handle,
  modalVisible,
  setModalVisible,
  onArchive,
  cursorvalue,
}) => {
  const [userInfo, setUserInfo] = React.useState({});

  const DeleteModalVisible = (item, index) => {
    setModalVisible(true);
    setData({
      ...data,
      selectedData: { ...item, index: index },
    });
  };

  return (
    <>
      {modalVisible && (
        <DeleteAgreementModal
          setVisibleDelete={setModalVisible}
          data={data}
          handle={handle}
        />
      )}
      <div>
        <table className="w-full  mx-auto">
          <thead className="bg-[#E8F3FF] text-center">
            <tr className="text-left">
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-start">
                  Agreement Name
                </div>
              </th>
              <th className=" px-4 py-3 ">
                <div className="flex items-center justify-center">Actions</div>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {data?.data?.map((item, index) => (
              <tr key={index} className="border-b border-[#F0F0F0]">
                <td className="px-4 py-3">
                  <div className="flex items-center justify-start">
                    {item.name}
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="flex items-center justify-center">
                    <Link
                      to={`/admin/settings/payroll/agreements/edit/${item?.id}/${cursorvalue}`}
                      className="mx-2"
                    >
                      <FaEdit />
                    </Link>
                    <button
                      className="mx-2"
                      onClick={() => {
                        onArchive(item);
                      }}
                    >
                      <Archive />
                    </button>
                    <button
                      className="mx-2"
                      onClick={() => {
                        DeleteModalVisible(item, index);
                      }}
                    >
                      <FaTrashAlt />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AgreementTable;
