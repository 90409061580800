import React from "react";
import { DatePicker, Input, Modal, Select } from "antd";
import { Button } from "antd";
import { Controller, useForm } from "react-hook-form";
import { Fragment } from "react";
import { useState } from "react";
import { useEffect } from "react";
const EmployeePurchaseModal = ({
  visible,
  onCancel,
  isEditing,
  editData,
  onSave,
}) => {
  const modalTitle = isEditing
    ? "Editing Employee Purchase"
    : "Creating Employee Purchase";

  const { control, handleSubmit, reset } = useForm({
    defaultValues: isEditing ? editData : {},
  });

  useEffect(() => {
    if (visible && isEditing) {
      reset(editData);
    } else {
      reset();
    }
  }, [visible, isEditing, reset, editData]);

  const onSubmit = (data) => {
    onSave(data);
    reset();
  };

  return (
    <Modal
      title={modalTitle}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="item mt-5">
          <label htmlFor="name">Name</label>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => <Input {...field} className="mt-3" />}
          />
        </div>
        <div className="item mt-5">
          <label htmlFor="value">Value</label>
          <Controller
            name="value"
            control={control}
            defaultValue=""
            render={({ field }) => <Input {...field} className="mt-3" />}
          />
        </div>

        <div className="item mt-5">
          <label htmlFor="inclusive_gst">Inclusive GST</label>
          <Controller
            control={control}
            name="inclusive_gst"
            render={({ field }) => (
              <Input {...field} type="text" className="mt-3" />
            )}
          />
        </div>
        <div className="mt-5 flex justify-between items-center">
          <div>
            <label htmlFor="purchase_date">Date of Purchase</label>
            <Controller
              name="purchase_date"
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <Input
                  {...field}
                  type="date"
                  className="mt-3"
                  id="purchase_date"
                />
              )}
            />
          </div>
        </div>
        <div className="item mt-5 text-center">
          <Button htmlType="submit" className="btn btn-blue">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default EmployeePurchaseModal;
