import React, { useEffect, useState } from "react";
import HourlyRateTaskEstimations from "./hourlyRateTaskEstimations";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { GetHourlyRate } from "../../helper/setup/task/hourlyrate";
import { SearchData } from "../../helper/global/global";
import { SettingService } from "../../config/axiosUrl";
import {
  CreateEditProductiveOrder,
  CreateHourlyRate,
  GetAreaById,
} from "../../helper/worksite/worksiteDetails.js/areas";
import { toast } from "react-toastify";
import { TextShimmer } from "../shimmer/shimmer";

const AreaCardDetails = () => {
  let { id } = useParams();
  let { area } = useParams();
  let navigate = useNavigate();

  const initialvalue = {
    hourly_rate_task_id: "",
    worksite_id: "",
    area_id: "",
    hourly: "",
    po: "",
    total: "",
    remaining: "",
    net_alloc_hours: "",
    reserve_per: "",
    isLoading: false,
    isShimmer: true,
    task_ids: [],
    area: [],
    hourly_rate_tasks_estimations: [],
    productivity_orders: [],
    allProdOrdTaskIds: [],
    isAddNewLoading: false,
    animate_pulse: false,
  };

  const initialMultySelectValue = {
    options: [],
    isMultySelect: false,
    selectedOption: [],
    isLoading: false,
    isShimmer: true,
    title: "Available Hourly Rate Tasks",
    searchBarTitle: "Search by Hourly Rate Tasks",
    buttonTitle: "Add",
  };

  const [multySelect, setMultySelect] = useState(initialMultySelectValue);
  const [areaCards, setAreaCards] = useState(initialvalue);

  const handleAreas = {
    getAreasPage: () => {
      navigate(`/admin/worksite/details/areas/${id}`);
    },
    openMultyModal: () => {
      setMultySelect({
        ...multySelect,
        isMultySelect: true,
      });
    },
    closeMultyModal: () => {
      setMultySelect({
        ...multySelect,
        isMultySelect: false,
        isLoading: false,
      });
    },
    getHourlyRatetask: async (e) => {
      if (!e?.target?.value) {
        setMultySelect({
          ...multySelect,
          isShimmer: true,
          isMultySelect: true,
        });
      }

      const params = {
        value: e?.target?.value,
        api: "/api/v1/setting/task/get-hourly-rate-tasks-data",
        service: SettingService,
      };
      let data = await SearchData(params);
      if (data?.status) {
        let allOptions = [];
        data?.data?.data?.map((task, index) => {
          allOptions?.push({
            name:
              task?.abbreviation +
              " " +
              task?.name +
              " ( " +
              task?.categories?.name +
              " ) ",
            id: task?.id,
          });
        });
        setMultySelect({
          ...multySelect,
          options: allOptions,
          isShimmer: false,
          isMultySelect: true,
        });
      }
    },
    getMerge: async () => {
      navigate(`/admin/worksite/details/areas/cards/merge/${id}/${area}`);
    },
    addHourlyRateTask: async () => {
      setMultySelect({
        ...multySelect,
        isLoading: true,
      });

      const params = {
        ids: multySelect?.selectedOption,
        worksite_id: id,
        area_id: area,
      };

      let data = await CreateHourlyRate(params);

      if (data?.status) {
        let getData = await handleAreas?.getAreaById();
        if (getData?.status) {
          toast?.success(data?.message);
          handleAreas?.closeMultyModal();
        }
      } else {
        toast?.error(data?.message);
        setMultySelect({
          ...multySelect,
          isLoading: false,
        });
      }
    },
    getAreaById: async () => {
      const params = {
        id: area,
      };
      let data = await GetAreaById(params);
      if (data?.status) {
        let allProdOrdTaskIds = [];
        data?.data?.data?.productivity_orders?.map((poTask, index) =>
          data?.data?.data?.productivity_orders?.[
            index
          ]?.productivity_orders_data?.map((task, ind) =>
            allProdOrdTaskIds?.push(task?.hourly_rate_tasks_estimations_id)
          )
        );

        console.log(allProdOrdTaskIds, "allProdOrdTaskIds");

        setAreaCards({
          ...areaCards,
          hourly_rate_tasks_estimations:
            data?.data?.data?.hourly_rate_tasks_estimations,
          productivity_orders: data?.data?.data?.productivity_orders,
          area: data?.data?.data,
          allProdOrdTaskIds: allProdOrdTaskIds,
          isShimmer: false,
          animate_pulse: false,
        });

        console.log(
          data?.data?.data?.productivity_orders,
          "data?.data?.data?.productivity_orders"
        );

        let allSelectedOption = [];
        data?.data?.data?.hourly_rate_tasks_estimations?.map((task, index) => {
          allSelectedOption?.push(task?.hourly_rate_task?.id);
        });

        setMultySelect({
          ...multySelect,
          selectedOption: allSelectedOption,
        });

        return data;
      } else {
        return false;
      }
    },
    addProductiveOrder: async () => {
      setAreaCards({
        ...areaCards,
        isAddNewLoading: true,
      });

      let allData = [];

      const params = {
        id: "",
        area_id: area,
        worksite_id: id,
        code:
          areaCards?.area?.abbreviation +
          " " +
          (areaCards?.productivity_orders?.length + 1),
        name:
          "New productivity order " +
          (areaCards?.productivity_orders?.length + 1),
        status: 1,
        is_save: 1,
      };

      let data = await CreateEditProductiveOrder(params);

      console.log(data?.data?.data, "CreateEditProductiveOrder");
      if (data?.status) {
        allData?.push(data?.data?.data);
        toast?.success(data?.message);

        let newProductiveOrder = [];
        newProductiveOrder?.push({ ...data?.data?.data });
        let allProductiveOrder = [
          ...newProductiveOrder,
          ...areaCards?.productivity_orders,
        ];

        setAreaCards({
          ...areaCards,
          productivity_orders: allProductiveOrder,
          isAddNewLoading: false,
        });
      } else {
        toast?.error(data?.message);
        setAreaCards({
          ...areaCards,
          isAddNewLoading: false,
        });
      }
    },
    addProductiveOrderName: async () => {},
  };

  useEffect(() => {
    (async () => {
      await handleAreas?.getAreaById();
    })();
  }, [area]);

  return (
    <div className="px-4">
      {areaCards?.isShimmer ? (
        <TextShimmer data={{ gap: 15, line: 20 }} />
      ) : (
        <div className={areaCards?.animate_pulse ? "animate-pulse" : ""}>
          <HourlyRateTaskEstimations
            multySelect={multySelect}
            setMultySelect={setMultySelect}
            data={areaCards}
            setData={setAreaCards}
            handle={handleAreas}
          />
        </div>
      )}
    </div>
  );
};

export default AreaCardDetails;
