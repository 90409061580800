import React from "react";
import { Route, Routes } from "react-router-dom";
import SiteAllowence from "../AllowenceItems/SiteAllowance";
import WorkerAllowence from "../AllowenceItems/WorkerAllowance";
import PayrollAllowance from "../AllowenceItems/PayrollAllowance";
import TaskAllowence from "../AllowenceItems/TaskAllowance";

const AllowanceRoutes = () => {
  return (
    <Routes>
      <Route path="site-allowance" element={<SiteAllowence />} />
      <Route path="worker-allowance" element={<WorkerAllowence />} />
      <Route path="task-allowance" element={<TaskAllowence />} />
      <Route path="payroll-category-allowance" element={<PayrollAllowance />} />
    </Routes>
  );
};

export default AllowanceRoutes;
