import { Button, DatePicker, Input } from "antd";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import TimesheetDetailsSubsection from "./TimesheetDetailsSubsection";
import WorkerTimeline from "./WorkerTimeline";
import WorkerBreakdown from "./WorkerBreakdown";
import moment from "moment";

const path = "/admin/worksite/details/timesheet";
const tabContent = [
  {
    key: "timesheet-details",
    label: `TimeSheet Details`,
  },
  {
    key: "worker-timeline",
    label: `Worker Timeline`,
  },
  {
    key: "work-breakdown",
    label: `Work Breakdown`,
  },
];

/* function Button({ children, className = "", style = {} }) {
  return (
    <button
      className={`text-lg p-1 border-solid border-[#111111] h-100 ${className}`}
      style={{
        borderWidth: "1px",
        ...style,
      }}
    >
      {children}
    </button>
  );
} */

const TimesheetDetails = () => {
  const navigate = useNavigate();
  const { id = "", weekDayId = "" } = useParams();
  const [activeTab, setActiveTab] = useState("timesheet-details");

  const [activeDate, setActiveDate] = useState();

  function onTabClick(item) {
    if (item) {
      setActiveTab(item.key);
      let route = `${path}/${id}/${weekDayId}/${item.key}`;
      navigate(route);
    } else {
      navigate(`${path}/${id}`);
    }
  }

  const getDate = {
    selected: (e) => {
      setActiveDate(e?.target?.value);
    },
    next: (e) => {
      let date = moment(activeDate).add(1, "days").format("YYYY-MM-DD");
      setActiveDate(date);
    },
    previous: (today) => {
      let date = moment(activeDate).subtract(1, "days").format("YYYY-MM-DD");
      setActiveDate(date);
    },
  };

  useEffect(() => {
    let origin = window.location.href.split("/");
    let length = origin.length - 1;
    const findItem = tabContent.find((item) => item.key === origin[length]);
    if (findItem) {
      setActiveTab(findItem.key);
    }
  });

  return (
    <div className="flex flex-col gap-3 p-2 py-3">
      <div className="bg-white flex justify-center relative">
        <span
          className="inline-flex items-center gap-1 absolute left-0 select-none cursor-pointer"
          onClick={() => onTabClick()}
        >
          <FaArrowLeft />
          <p className="font-[#0F4C7D]">Return to TimeSheets</p>
        </span>
        <div className="inline-flex items-center gap-2">
          <group className="inline-flex items-center h-100 border border-black">
            <span className="border-r border-y-0 border-l-0 border-black rounded-none">
              <Button
                className="border-0 rounded-none hover:border-0 focus:border-0"
                onClick={getDate?.previous}
              >
                <HiChevronLeft className="fill-[#11111]" />
              </Button>
            </span>

            <Input
              type="date"
              className="border-0 rounded-none hover:border-0 focus:border-0"
              format={"DD MMM YYYY"}
              onChange={getDate?.selected}
              value={activeDate}
            />
            <span className="border-l border-y-0 border-r-0 border-black rounded-none">
              <Button
                className="border-0 rounded-none hover:border-0 focus:border-0"
                onClick={getDate?.next}
              >
                <HiChevronRight className="fill-[#11111]" />
              </Button>
            </span>
          </group>
        </div>
      </div>
      {/* <Tabs items={items} activeKey="2" /> */}
      <nav className="flex gap-2 bg-[#E8E8E8] p-2 font-lexend w-100">
        {tabContent.map((item) => (
          <button
            className={`p-2 px-4 font-sm rounded-md duration-250 ${
              activeTab === item.key
                ? "bg-[#0F4C7D] text-white"
                : "text-[#0F4C7D] bg-[white] border-2 border-[#0F4C7D]"
            }`}
            onClick={() => onTabClick(item)}
          >
            <p>{item.label}</p>
          </button>
        ))}
      </nav>
      <Routes>
        <Route
          path="timesheet-details"
          element={
            <TimesheetDetailsSubsection
              activeDate={activeDate}
              setActiveDate={setActiveDate}
            />
          }
        />
        <Route
          path="worker-timeline"
          element={
            <WorkerTimeline
              activeDate={activeDate}
              setActiveDate={setActiveDate}
            />
          }
        />
        <Route
          path="work-breakdown"
          element={
            <WorkerBreakdown
              activeDate={activeDate}
              setActiveDate={setActiveDate}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default TimesheetDetails;
